body {
    /*background: #fafafa;*/
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 50px;
    display: inline-block;
    margin-top: -5px;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.logo-home {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/logo_home.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/logo_home2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover, .hand, [jh-sort-by] {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    /*padding: 0;*/
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}

form ul.dropdown-menu{
	max-height: 210px;
    overflow-y: auto;
    width: 95%;
}



/* jhipster-needle-css-add-main JHipster will add new css style */


form.submitted .ng-invalid{
    border:1px solid #a94442;
}
.help-block{
	color: #a94442;
}

fieldset{
	min-width:100% !important;
}
.service-image img{
	height: 100%;
}

.special-chars {
	font-weight: bold;
	letter-spacing: 0.15em;
}
/*
.btn {
    margin-right: 8px;
}
*/
.angular-ui-tree-handle {
    background: #f8faff;
    border: 1px solid #dae2ea;
    color: #7c9eb2;
    padding: 10px 10px;
}

.angular-ui-tree-handle:hover {
    color: #d61524;
    background: #f4f6f7;
    border-color: #dce2e8;
    cursor: default;
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

tr.angular-ui-tree-empty {
    height:100px
}

.group-title {
    background-color: #687074 !important;
    color: #FFF !important;
}


/* --- Tree --- */
.tree-node {
    border: 1px solid #dae2ea;
    background: #f8faff;
    color: #d61524;
}

.nodrop {
    background-color: #f2dede;
}

.tree-node-content {
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.tree-handle {
    padding: 10px;
    background: #428bca;
    color: #FFF;
    margin-right: 10px;
}

.angular-ui-tree-handle:hover {
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.selected-node, .selected-node:hover{
	border:2px solid #d61524;
}

.angular-ui-tree-nodes .angular-ui-tree-nodes{
	padding-left: 30px;
}

div.details{
	border: 1px solid #dae2ea;
    margin-top: 10px;
    padding: 2px;
}

div.details h4{
	margin-left: 4px;
}
/*+++++*/

a .glyphicon-spin,
fieldset .panel-heading a.panel-title:hover {
	text-decoration: none
}

.tabs-left>.nav-tabs>li:focus,
.tabs-left>.nav-tabs>li>a:focus,
.tabs-right>.nav-tabs>li:focus,
.tabs-right>.nav-tabs>li>a:focus {
	outline: 0
}

html.overlay-open .navbar-fixed-top {
	z-index: 400
}

html.js fieldset.collapsed {
	border-left-width: 1px;
	border-right-width: 1px;
	height: auto
}

html.js input.form-autocomplete {
	background-image: none
}

html.js .autocomplete-throbber {
	background-position: 100% 2px;
	background-repeat: no-repeat;
	display: inline-block;
	height: 15px;
	margin: 2px 0 0 2px;
	width: 15px
}

html.js .autocomplete-throbber.throbbing {
	background-position: 100% -18px
}

body {
	position: relative
}

body.admin-expanded.admin-vertical.admin-nw .navbar,
body.admin-expanded.admin-vertical.admin-sw .navbar {
	margin-left: 260px
}

body.navbar-is-fixed-top {
	padding-top: 64px
}

body.navbar-is-fixed-bottom {
	padding-bottom: 64px!important
}

body.toolbar {
	padding-top: 30px!important
}

body.toolbar .navbar-fixed-top {
	top: 30px
}

body.toolbar.navbar-is-fixed-top {
	padding-top: 94px!important
}

body.toolbar-drawer {
	padding-top: 64px!important
}

body.toolbar-drawer .navbar-fixed-top {
	top: 64px
}

body.toolbar-drawer.navbar-is-fixed-top {
	padding-top: 128px!important
}

body.admin-menu .navbar-fixed-top {
	top: 29px
}

body.admin-menu.navbar-is-fixed-top {
	padding-top: 93px
}

body div#admin-toolbar {
	z-index: 1600
}

body #admin-menu,
body #admin-toolbar,
body #toolbar {
	-webkit-box-shadow: none;
	box-shadow: none
}

body #admin-menu {
	margin: 0;
	padding: 0;
	position: fixed;
	z-index: 1600
}

body #admin-menu .dropdown li {
	line-height: normal
}

@media screen and (min-width:768px) {
	.navbar.container {
		max-width: 720px
	}
}

@media screen and (min-width:992px) {
	.navbar.container {
		max-width: 940px
	}
}

@media screen and (min-width:1200px) {
	.navbar.container {
		max-width: 1140px
	}
}
/*
.navbar.container,
.navbar.container-fluid {
	margin-top: 20px
}*/

.navbar.container-fluid>.container-fluid,
.navbar.container>.container {
	margin: 0;
	padding: 0;
	width: auto
}

#overlay-container,
.overlay-element,
.overlay-modal-background {
	z-index: 1500
}

#toolbar {
	z-index: 1600
}

.modal {
	z-index: 1620
}

.modal-dialog {
	z-index: 1630
}

.ctools-modal-dialog .modal-body {
	width: 100%!important;
	overflow: auto
}

.book-toc>.dropdown-menu,
.region-help .block,
div.image-widget-data {
	overflow: hidden
}

.modal-backdrop {
	z-index: 1610
}

.footer {
	margin-top: 45px;
	padding-top: 35px;
	padding-bottom: 36px;
	border-top: 1px solid #E5E5E5
}

.element-invisible {
	margin: 0;
	padding: 0;
	width: 1px
}

.navbar .logo {
	margin-right: -15px;
	padding-left: 15px;
	padding-right: 15px
}

ul.secondary {
	float: left
}

@media screen and (min-width:768px) {
	.navbar .logo {
		margin-right: 0;
		padding-left: 0
	}
	ul.secondary {
		float: right
	}
}

.page-header,
.region-sidebar-first .block:first-child h2.block-title,
.region-sidebar-second .block:first-child h2.block-title {
	margin-top: 0
}

p:last-child {
	margin-bottom: 0
}

.region-help>.glyphicon {
	font-size: 18px;
	float: left;
	margin: -.05em .5em 0 0
}

form#search-block-form {
	margin: 0
}

.navbar #block-search-form {
	float: none;
	margin: 5px 0 5px 5px
}

@media screen and (min-width:992px) {
	.navbar #block-search-form {
		float: right
	}
}

.navbar #block-search-form .input-group-btn {
	width: auto
}

ul.action-links {
	margin: 12px 0;
	padding: 0
}

ul.action-links li {
	display: inline;
	margin: 0;
	padding: 0 6px 0 0
}

ul.action-links .glyphicon {
	padding-right: .5em
}

.uneditable-input,
input,
select,
textarea {
	max-width: 100%;
	width: auto
}

.filter-wrapper .form-type-select .filter-list,
.managed-files.table td:first-child {
	width: 100%
}

input.error {
	color: #a94442;
	border-color: #ebccd1
}

fieldset legend.panel-heading {
	float: left;
	line-height: 1em;
	margin: 0
}

fieldset .panel-body {
	clear: both;
	display: inherit
}

.form-type-password-confirm label,
.panel-heading {
	display: block
}

fieldset .panel-heading a.panel-title {
	color: inherit;
	display: block;
	margin: -10px -15px;
	padding: 10px 15px
}

.form-horizontal .form-group {
	margin-left: 0;
	margin-right: 0
}

.form-actions {
	clear: both
}

div.image-widget-data {
	float: none
}

table.sticky-header {
	z-index: 1
}

.resizable-textarea textarea {
	border-radius: 4px 4px 0 0
}

.ajax-progress-bar,
.filter-wrapper {
	border-radius: 0 0 4px 4px
}

.text-format-wrapper {
	margin-bottom: 15px
}

.text-format-wrapper .filter-wrapper,
.text-format-wrapper>.form-type-textarea {
	margin-bottom: 0
}

.filter-wrapper .panel-body {
	padding: 7px
}

.filter-wrapper .form-type-select {
	min-width: 30%
}

.filter-help {
	margin-top: 5px;
	text-align: center;
}

@media screen and (min-width:768px) {
	.filter-help {
		float: right
	}
}

.filter-help .glyphicon {
	margin: 0 5px 0 0;
	vertical-align: text-top
}

.checkbox:first-child,
.radio:first-child {
	margin-top: 0
}

.checkbox:last-child,
.radio:last-child {
	margin-bottom: 0
}

.control-group .help-inline,
.help-block {
	font-size: 14px;
	margin: 5px 0 10px;
	padding: 0
}

a.tabledrag-handle .handle {
	height: auto;
	width: auto
}

.error {
	color: #a94442
}

div.error,
table tr.error {
	background-color: #f2dede;
	color: #a94442
}

.form-group.error,
.form-group.has-error {
	background: 0 0
}

.form-group.error .control-label,
.form-group.error label,
.form-group.has-error .control-label,
.form-group.has-error label {
	color: #a94442;
	font-weight: 600
}

.form-group.error .uneditable-input,
.form-group.error input,
.form-group.error select,
.form-group.error textarea,
.form-group.has-error .uneditable-input,
.form-group.has-error input,
.form-group.has-error select,
.form-group.has-error textarea {
	color: #555
}

/*
.form-group.error .help-block,
.form-group.error .help-inline,
.form-group.has-error .help-block,
.form-group.has-error .help-inline,
.submitted {
	color: #777
}
*/
.nav-tabs {
	margin-bottom: 10px
}

ul li.collapsed,
ul li.expanded,
ul li.leaf {
	list-style: none
}

.tabs--secondary {
	margin: 0 0 10px
}

.submitted {
	margin-bottom: 1em;
	font-style: italic;
	font-weight: 400
}

.alert a,
.book-toc>.dropdown-menu>li:nth-child(1)>a {
	font-weight: 700
}

.form-type-password-confirm {
	position: relative
}

.form-type-password-confirm label .label {
	float: right
}

.form-type-password-confirm .password-help {
	padding-left: 2em
}

@media (min-width:768px) {
	.form-type-password-confirm .password-help {
		border-left: 1px solid #ddd;
		left: 50%;
		margin-left: 15px;
		position: absolute
	}
}

@media (min-width:992px) {
	.form-type-password-confirm .password-help {
		left: 33.33333333%
	}
}

.form-type-password-confirm .progress {
	background: 0 0;
	border-radius: 0 0 5px 5px;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 4px;
	margin: -5px 0 0
}

.form-type-password-confirm .form-type-password {
	clear: left
}

.form-type-password-confirm .form-control-feedback {
	right: 15px
}

.form-type-password-confirm .help-block {
	clear: both
}

ul.pagination li>a.progress-disabled {
	float: left
}

.form-autocomplete .glyphicon {
	color: #777;
	font-size: 120%
}

.form-autocomplete .glyphicon.glyphicon-spin {
	color: #337ab7
}

.form-autocomplete .input-group-addon {
	background-color: #fff
}

.form-autocomplete .dropdown a {
	white-space: normal
}

.ajax-progress-bar {
	border: 1px solid #ccc;
	margin: -1px 0 0;
	padding: 6px 12px;
	width: 100%
}

.ajax-progress-bar .progress {
	height: 8px;
	margin: 0
}

.ajax-progress-bar .message,
.ajax-progress-bar .percentage {
	color: #777;
	font-size: 12px;
	line-height: 1em;
	margin: 5px 0 0;
	padding: 0
}

.glyphicon-spin {
	display: inline-block;
	-o-animation: spin 1s infinite linear;
	-webkit-animation: spin 1s infinite linear;
	animation: spin 1s infinite linear
}

a .glyphicon-spin {
	display: inline-block
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0)
	}
	100% {
		-webkit-transform: rotate(359deg)
	}
}

@-o-keyframes spin {
	0% {
		-o-transform: rotate(0)
	}
	100% {
		-o-transform: rotate(359deg)
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0);
		-o-transform: rotate(0);
		transform: rotate(0)
	}
	100% {
		-webkit-transform: rotate(359deg);
		-o-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

.tabbable {
	margin-bottom: 20px
}

.tabs-below>.nav-tabs,
.tabs-left>.nav-tabs,
.tabs-right>.nav-tabs {
	border-bottom: 0
}

.tabs-below>.nav-tabs .summary,
.tabs-left>.nav-tabs .summary,
.tabs-right>.nav-tabs .summary {
	color: #777;
	font-size: 12px
}

.tab-pane>.panel-heading {
	display: none
}

.tab-content>.active {
	display: block
}

.tabs-below>.nav-tabs {
	border-top: 1px solid #ddd
}

.tabs-below>.nav-tabs>li {
	margin-top: -1px;
	margin-bottom: 0
}

.tabs-below>.nav-tabs>li>a {
	border-radius: 0 0 4px 4px
}

.tabs-below>.nav-tabs>li>a:focus,
.tabs-below>.nav-tabs>li>a:hover {
	border-top-color: #ddd;
	border-bottom-color: transparent
}

.tabs-below>.nav-tabs>.active>a,
.tabs-below>.nav-tabs>.active>a:focus,
.tabs-below>.nav-tabs>.active>a:hover {
	border-color: transparent #ddd #ddd
}

.tabs-left>.nav-tabs,
.tabs-right>.nav-tabs {
	padding-bottom: 20px;
	width: 220px
}

.tabs-left>.nav-tabs>li,
.tabs-right>.nav-tabs>li {
	float: none
}

.tabs-left>.nav-tabs>li>a,
.tabs-right>.nav-tabs>li>a {
	margin-right: 0;
	margin-bottom: 3px
}

.tabs-left>.tab-content,
.tabs-right>.tab-content {
	border-radius: 0 4px 4px;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
	box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
	border: 1px solid #ddd;
	overflow: hidden;
	padding: 10px 15px
}

.tabs-left>.nav-tabs {
	float: left;
	margin-right: -1px
}

.tabs-left>.nav-tabs>li>a {
	border-radius: 4px 0 0 4px
}

.tabs-left>.nav-tabs>li>a:focus,
.tabs-left>.nav-tabs>li>a:hover {
	border-color: #eee #ddd #eee #eee
}

.tabs-left>.nav-tabs>.active>a,
.tabs-left>.nav-tabs>.active>a:focus,
.tabs-left>.nav-tabs>.active>a:hover {
	border-color: #ddd transparent #ddd #ddd;
	-webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, .05);
	box-shadow: -1px 1px 1px rgba(0, 0, 0, .05)
}

.tabs-right>.nav-tabs {
	float: right;
	margin-left: -1px
}

.tabs-right>.nav-tabs>li>a {
	border-radius: 0 4px 4px 0
}

.tabs-right>.nav-tabs>li>a:focus,
.tabs-right>.nav-tabs>li>a:hover {
	border-color: #eee #eee #eee #ddd;
	-webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, .05);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, .05)
}

.tabs-right>.nav-tabs>.active>a,
.tabs-right>.nav-tabs>.active>a:focus,
.tabs-right>.nav-tabs>.active>a:hover {
	border-color: #ddd #ddd #ddd transparent
}

td.checkbox,
td.radio,
th.checkbox,
th.radio {
	display: table-cell
}

.views-display-settings .label {
	font-size: 100%;
	color: #666
}

.views-display-settings .footer {
	padding: 0;
	margin: 4px 0 0
}

.views-exposed-form .views-exposed-widget .btn {
	margin-top: 1.8em
}

table .checkbox input[type=checkbox],
table .radio input[type=radio] {
	max-width: inherit
}

.form-horizontal .form-group label {
	position: relative;
	min-height: 1px;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px;
	padding-left: 15px;
	padding-right: 15px;
	text-align: right;
}

@media (min-width:768px) {
	.form-horizontal .form-group label {
		float: left;
		width: 16.66666667%
	}
	body.navbar-is-fixed-top.navbar-administration.navbar-vertical.navbar-tray-open .navbar-fixed-top {
		left: 240px;
		left: 24rem
	}
}

.alert-success a,
.alert-success a:focus,
.alert-success a:hover {
	color: #2b542c
}

.alert-info a,
.alert-info a:focus,
.alert-info a:hover {
	color: #245269
}

.alert-warning a,
.alert-warning a:focus,
.alert-warning a:hover {
	color: #66512c
}

.alert-danger a,
.alert-danger a:focus,
.alert-danger a:hover {
	color: #843534
}

div.image-style-new,
div.image-style-new div {
	display: block
}

div.image-style-new div.input-group {
	display: table
}

.table-striped>tbody>tr:nth-child(odd)>td.module,
.table>tbody>tr>td.module,
td.module {
	background: #ddd;
	font-weight: 700
}

.book-toc>.dropdown-menu>.dropdown-header {
	white-space: nowrap
}

.book-toc>.dropdown-menu .dropdown-menu {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 0;
	display: block;
	font-size: 12px;
	margin: 0;
	padding: 0;
	position: static;
	width: 100%
}

.book-toc>.dropdown-menu .dropdown-menu>li {
	padding-left: 20px
}

.book-toc>.dropdown-menu .dropdown-menu>li>a {
	margin-left: -40px;
	padding-left: 60px
}

#features-filter .form-item.form-type-checkbox {
	padding-left: 20px
}

fieldset.features-export-component {
	font-size: 12px
}

fieldset.features-export-component,
html.js #features-export-form fieldset.features-export-component {
	margin: 0 0 10px
}

fieldset.features-export-component .panel-heading {
	padding: 5px 10px
}

fieldset.features-export-component .panel-heading a.panel-title {
	font-size: 12px;
	font-weight: 500;
	margin: -5px -10px;
	padding: 5px 10px
}

fieldset.features-export-component .panel-body {
	padding: 0 10px
}

div.features-export-list {
	margin: -11px 0 10px;
	padding: 0 10px
}

div.features-export-list .form-type-checkbox,
fieldset.features-export-component .component-select .form-type-checkbox {
	line-height: 1em;
	margin: 5px 5px 5px 0!important;
	min-height: 0;
	padding: 3px 3px 3px 25px!important
}

div.features-export-list .form-type-checkbox input[type=checkbox],
fieldset.features-export-component .component-select .form-type-checkbox input[type=checkbox] {
	margin-top: 0
}

body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open .navbar-fixed-top {
	top: 79px
}

body.navbar-is-fixed-top.navbar-administration .navbar-fixed-top {
	top: 39px
}

.navbar-administration #navbar-administration.navbar-oriented .navbar-bar {
	z-index: 1032
}

.navbar-administration #navbar-administration .navbar-tray {
	z-index: 1031
}

body.navbar-is-fixed-top.navbar-administration {
	padding-top: 103px!important
}

body.navbar-is-fixed-top.navbar-administration.navbar-horizontal.navbar-tray-open {
	padding-top: 143px!important
}

body.navbar-tray-open.navbar-vertical.navbar-fixed {
	margin-left: 24rem
}

#navbar-administration.navbar-oriented .navbar-tray-vertical {
	width: 24rem
}

a.icon-before .glyphicon {
	margin-right: .25em
}

a.icon-after .glyphicon {
	margin-left: .25em
}

.btn.icon-before .glyphicon {
	margin-left: -.25em;
	margin-right: .25em
}

.btn.icon-after .glyphicon {
	margin-left: .25em;
	margin-right: -.25em
}

@font-face {
  font-family: "Abel-Regular";
  src: url(../font/Abel-Regular.eot); /* IE */
}

@font-face {
  font-family: "Abel-Regular";
  src: local("Abel-Regular"),
         local("Abel-Regular"),
         url(../font/Abel-Regular.ttf) format("truetype"); /* non-IE */
}

html.hidden {
	display: inline !important;
}

html {
	font-size: 18px;
}

body {
	font: 18px/1.2 Mako,Helvetica,sans-serif,Arial;
	color: #000000;
	min-width: 280px;
}

.bold {
	font-family: 'Abel-Regular', sans-serif;
}

.normal,
.normal h2 {
	font-family: Mako,Helvetica,sans-serif,Arial;
}

.navbar.container-fluid>.container-fluid,
.navbar.container>.container {
	margin: inherit;
	padding: inherit;
	width: inherit;
}

.navbar.container {
	max-width: 1280px;
	padding-top: 25px;
    margin-bottom: 40px;
}

#navbar>.row {
	margin: 0;
}

.navbar-header h1 {
	margin-top: 0px;
	margin-bottom: 0px;
}

a {
	color: #d61524;
	text-decoration: none;
}

a:hover {
	color: #d61524;
	text-decoration: underline;
	outline: 0;
}

a:focus {
	color: #d61524;
	text-decoration: underline;
	outline: dotted thin;
}

.white,
.white a,
.white a:hover,
.white a:focus {
	color: #ffffff;
}

.black,
.black a,
.black a:hover,
.black a:focus {
	color: #000000;
}

/* strong, */
/* .bold { */
/* 	font-family: 'Abel-Regular'; */
/* 	font-weight: normal; */
/* } */

.smalltext,
.smalltext a {
	font-size: 15px;
}

.icon_file a {
	background: #d2d2d2 url(/sites/all/themes/unimib/img/document.png) no-repeat scroll 8px 8px;
	display: inline-block;
	margin: 10px 0;
	padding: 8px 8px 8px 33px;
	width: auto;
	color: #000000;
}

.nav>li>a:hover,
.nav>li>a:focus {
	background: none;
}

.nav>li>a:focus {
	text-decoration: underline;
	outline: dotted thin;
}

.nav span.caret {
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	color: inherit;
	font-family: 'Abel-Regular';
	font-weight: inherit;
	line-height: 100%;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	margin-bottom: 18px;
	margin-top: 20px;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin-bottom: 18px;
	margin-top: 20px;
}

h1,
.h1 {
	font-size: 56px;
}

h2,
.h2 {
	font-size: 48px;
}

h3,
.h3 {
	font-size: 30px;
}

h4,
.h4 {
	font-size: 24px;
}

h5,
.h5 {
	font-size: 20px;
}

h6,
.h6 {
	font-size: 18px;
}

ul.menu-corsi-sx span.separator,
span.separator {
	display: none;
}

ul.menu-corsi-sx span.nolink,
footer span.separator {
	display: none;
}

/*
.btn,
.btn:hover,
.btn:focus,
.btn.focus,
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
	border: medium none;
	border-radius: 0;
	color: #fff;
	background-color: #d61524;
	background-color: rgba(167, 30, 59, 0.8);
}
*/

.input-group-addon {
	border-radius: 0;
	padding: 5px 8px;
}

.button>a,
.button button,
.button .more-link a {
	width: 250px;
	padding: 19px 30px 19px 20px;
	display: block;
	margin: 30px auto 0;
	font-size: 23px;
	background-repeat: no-repeat;
	background-position: right 25px;
	font-family: 'Abel-Regular', sans-serif;
}

.button.areabg>a,
.areabg button,
.button.areabg .more-link a {
	color: #ffffff;
}

.area-20 .button.areabg>a,
.area-20 .areabg button,
.area-20 .button.areabg .more-link a {
	color: #000000;
}

.area-24 .button.areabg>a,
.area-24 .areabg button,
.area-24 .button.areabg .more-link a {
	color: #000000;
}

.button.fullwidth>a,
.button.fullwidth button,
.button.fullwidth .more-link a {
	display: inline-block;
	width: auto;
	background-position: right 8px;
	padding: 5px 45px 5px 15px;
	margin-top: 10px;
}

.button.fullwidth {
	width: auto;
}

.pager-load-more.pager li>a,
.pager-load-more.pager li>a:hover {
	font-size: 17px;
	background-position: right 15px;
	background-repeat: no-repeat;
	font-family: 'Abel-Regular', sans-serif;
	display: block;
	padding: 11px 30px 11px 10px;
	width: 120px;
	margin: 0 auto;
}

.button.width100,
.button.width100>a,
.button.width100 button,
.button.width100 .more-link a {
	width: 100%;
	max-width: 100%;
}

.button.mar15>a,
.button.mar15 button,
.button.mar15 .more-link a {
	margin: 15px auto;
}

.small.button>a,
.small button,
.small.button .more-link a {
	font-size: 17px;
	padding: 11px 30px 11px 15px;
	background-position: right 15px;
}

.button.redbg>a,
.redbg button,
.button.redbg .more-link a,
.button.areabg>a,
.areabg button,
.button.areabg .more-link a,
.pager-load-more.pager li>a,
.pager-load-more.pager li>a:hover {
	background-color: #d61524;
	background-color: rgba(167, 30, 59, 0.8);
}

.area-20 button,
.area-20 .button.areabg>a,
.area-20 .button.areabg .more-link a {
	background-color: #e5c200;
}

.area-21 button,
.area-21 .button.areabg>a,
.area-21 .button.areabg .more-link a {
	background-color: #005096;
}

.area-22 button,
.area-22 .button.areabg>a,
.area-22 .button.areabg .more-link a {
	background-color: #00753b;
}

.area-23 button,
.area-23 .button.areabg>a,
.area-23 .button.areabg .more-link a {
	background-color: #e63424;
}

.area-24 button,
.area-24 .button.areabg>a,
.area-24 .button.areabg .more-link a {
	background-color: #f29400;
}

.area-25 button,
.area-25 .button.areabg>a,
.area-25 .button.areabg .more-link a {
	background-color: #a4224b;
}

.area-26 button,
.area-26 .button.areabg>a,
.area-26 .button.areabg .more-link a {
	background-color: #b82585;
}

.button.graybg>a,
.graybg button,
.button.graybg .more-link a {
	background-color: #efefef;
	background-color: rgba(239, 239, 239, 0.8);
}

.button.arrow>a,
.arrow button,
.button.arrow .more-link a,
.pager-load-more.pager li>a {
	background-image: url("/sites/all/themes/unimib/img/arrowwhite_space.png");
}

.button.arrow-white>a,
.arrow-white button,
.button.arrow-white .more-link a {
	background-image: url("/sites/all/themes/unimib/img/arrowwhite.png");
}

.button.arrow-red>a,
.arrow-red button,
.button.arrow-red .more-link a {
	background-image: url("/sites/all/themes/unimib/img/arrowred.png");
}

.button.arrow-gray>a,
.arrow-gray button,
.button.arrow-gray .more-link a {
	background-image: url("/sites/all/themes/unimib/img/arrowgrey.png");
}

.nav .button.arrow>a {
	background-image: none;
}

.button.down>a {
	background-image: url("/sites/all/themes/unimib/img/down.png");
}

.button.absolute>a,
.absolute button,
.button.absolute .more-link a {
	position: absolute;
	bottom: 20px;
	margin: 0;
}

.view-mode-scheda .button.absolute>a {
	bottom: -64px;
}

.button-reset .button>a {
	background: none;
	margin: 0;
	padding: 0;
	display: block;
	font-family: Mako,Helvetica,sans-serif,Arial;
}

.button-softreset .button>a {
	background: none;
	margin: 0;
	padding: 0;
	display: block;
	text-decoration: underline;
}

.row_f2 .button>a {
	font-size: 13px;
}

.pager-load-more.pager li>a,
.pager-load-more.pager li>a:hover {
	color: #ffffff;
}

.zebralist .ui-accordion .ui-accordion-content {
	padding: 0;
}

.zebralist .views-row {
	padding: 20px;
	border-bottom: 1px solid #c3c3c3;
}

.zebralist .views-row-odd {
	background: #f2f2f2;
}

.capital {
	text-transform: lowercase;
}

.capital:first-letter {
	text-transform: uppercase;
}

.capital a {
	text-transform: uppercase;
}

body .file div.contextual-links-wrapper {
	display: none;
}

.field-name-field-testo-paragrafo table {
	width: 100% !important;
	height: 100% !important;
	background: #FFF;
	color: #000;
	border: 0 !important;
}

.field-name-field-testo-paragrafo thead,
tbody,
tr {
	border: 0;
	border-spacing: 0px !important;
	border-collapse: collapse;
	font-size:14px;
}

field-name-field-testo-paragrafo th {
	background: #d2d2d2;
	border: 0;
	padding: 7px 5px 5px 5px;
}

field-name-field-testo-paragrafo td {
	border: 1px solid #d2d2d2;
	padding: 7px 5px 5px 5px;
}

#block-search-form {
	display: none;
}

#block-search-form {
	padding: 0 20px;
}

.google-cse .btn-primary,
.google-cse .btn-primary:hover,
.google-cse .btn-primary:focus,
.google-cse .btn-primary.focus {
	background-color: #000;
}

.google-cse.form-search input {
	background-image: none !important;
}

.form-control {
	border-radius: 0;
	box-shadow: none;
	transition: none;
	font-size: 16px;
	height: 32px;
}

.form-control:focus {
	border-color: #cccccc;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 0, 0, .4);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(0, 0, 0, .4);
}

.uppercase,
.uppercase a {
	text-transform: uppercase;
}

.color_red {
	color: #d61524;
}

.titolo_sezione h1,
#block-delta-blocks-page-title h1#page-title {
	padding-left: 10px;
	border-bottom: 1px #000000 solid;
	margin-bottom: 40px;
	margin-top: 0;
}

.titolo_box_1 {
	font-size: 30px;
	margin-bottom: 6px;
}

.titolo_box_2 {
	font-size: 35px;
	font-family: Mako,Helvetica,sans-serif,Arial;
	padding-top: 10px;
	padding-bottom: 0px;
	margin-top: 18px;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.titolo_box_2 {
		margin-top: 18.5px;
	}
}

.paragraphs-item-homebox.bgimg .titolo_box_2 {
	margin-top: 5px;
	max-height: 80px;
	overflow: hidden;
}

.paragraphs-item-homebox.bgimg.hititle .titolo_box_2 {
	margin-top: 0;
	max-height: 100%;
	overflow: visible;
}

.titolo_paragrafo {
	font: 30px/30px 'Abel-Regular', sans-serif;
	margin-bottom: 10px;
}

.titolo_paragrafo_1 {
	font: 35px/35px 'Abel-Regular', sans-serif;
}

.titolo-evidenziato .titolo_paragrafo {
	background-color: #d61524;
	color: #ffffff;
	margin-bottom: 20px;
	margin-top: 20px;
	padding: 12px 50px 12px 30px;
}

.field-name-field-paragrafo>.field-items>.field-item .titolo-evidenziato .titolo_paragrafo {
	margin-top: 0;
}

.data_box {
	font-size: 40px;
	font-family: 'Abel-Regular';
	text-align: center;
	margin-top: 25px;
}

.giorno_box {
	font-size: 28px;
	font-family: 'Abel-Regular';
	text-align: center;
	margin-top: 15px;
	text-transform: lowercase;
}

.date-display-single .data_box {
	font-size: 40px;
	font-family: 'Abel-Regular';
	text-align: center;
	margin-top: 25px;
}

.date-display-single .giorno_box {
	font-size: 28px;
	font-family: 'Abel-Regular';
	text-align: center;
	margin-top: 15px;
	text-transform: lowercase;
}

.area_contenuto {
	font-size: 28px;
	font-family: 'Abel-Regular';
	color: #ffffff;
	background: #d61524 none repeat scroll 0 0;
	line-height: 54px;
	padding-left: 25px;
	margin-top: 30px;
	margin-bottom: 30px;
}

p,
p:last-child {
	margin: 0 0 18px;
}

.node-scheda-informativa p:last-child {
	margin: 0;
}

.margin30 {
	margin: 30px 0;
}

.page-taxonomy .margine_sotto {
	border-bottom: 1px #000000 solid;
	margin-top: 20px;
}

.page-taxonomy .margine_sotto.last {
	border-bottom: none;
}

ul,
ol {
	margin-bottom: 0;
	margin-top: 0;
}

.nav>li>a {
	color: #d61524;
	text-transform: uppercase;
	padding: 8px 15px;
}
/*
.row {
	margin-left: -20px;
	margin-right: -20px;
}
*/
.row_red {
	background: #d61524;
	margin-top: 80px;
}

.not-front .row_gray .container {
	background: #cfcfcf;
}

.page-taxonomy-term .row_gray {
	margin-top: 60px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	padding-left: 20px;
	padding-right: 20px;
}

.container {
	padding-left: 20px;
	padding-right: 20px;
}

.region-content-grey {
	background: #f2f2f2;
}

.fullwidth .container,
.container.fullwidth {
	padding-left: 0;
	padding-right: 0;
}

#content-header {
	margin-top: 35px;
}

.breadcrumb {
	background-color: #f2f2f2;
	border-radius: 0;
	margin: 0;
	padding: 16px 10px;
}

.breadcrumb>li+li::before {
	color: #000000;
	content: ">";
}

.breadcrumb a {
	color: #000000;
}

.breadcrumb>.active {
	color: #d61524;
	font-family: 'Abel-Regular';
}

.grigio .row {
	background: #cfcfcf;
}

.rosso .row {
	background: #d61524;
	color: #ffffff;
}

#block-sitemap-sitemap .level-1 {
	font: 20px/30px 'Abel-Regular', sans-serif;
}

#block-sitemap-sitemap .level-2 {
	font: 18px/20px Mako,Helvetica,sans-serif,Arial;
}

#block-sitemap-sitemap .level-2,
#block-sitemap-sitemap .level-3,
#block-sitemap-sitemap .level-4,
#block-sitemap-sitemap .level-5 {
	padding-left: 20px;
}

.page-node-1550 .breadcrumb {
	display: none;
}

.site-title-int {
	float: none;
	margin: 0 auto;
	max-width: 1280px;
	clear: both;
	padding: 0 20px;
}

.site-title-int>p {
	font-size: 50px;
}

body .row_f1 {
	background: #d61524;
	color: #ffffff;
}

body .row_f2 {
	background: #000000;
	color: #ffffff;
}

.bottom_space {
	margin-bottom: 30px;
}

.webform-container-inline.webform-datepicker div.form-item.form-type-select {
	display: none;
}

body .background_image {
	width: 100%;
	position: relative;
}

.navbar.container .col-xs-12 {
	padding-left: 0px;
	padding-right: 0px;
}

.navbar-collapse,
.navbar .logo {
	padding-left: 0px;
	padding-right: 0px;
}

.navbar-collapse {
	top: 120px;
}

.navbar-default {
	background-color: #ffffff;
}

.navbar-btn {
	margin-top: 0px;
	margin-bottom: 0px;
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
	background-color: transparent;
	border-color: #d61524;
}

.navbar {
	border: none;
	filter: none;
	text-shadow: none;
	border-radius: 0;
	/*min-height: 20px;*/
	margin-top: 0;
	margin-bottom: 20px;
	padding: 1px;
}

.int-24 .nav>li>a.active,
#block-menu-block-28 .nav>li>a.active,
#block-menu-block-10 .nav>li>a.active {
	font-family: 'Abel-Regular';
}

#block-menu-block-10 li.last {
	visibility: hidden;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}

.int-24 li.last {
	overflow: -moz-scrollbars-none;
}

.int-24 li.last::-webkit-scrollbar,
#block-menu-block-10 li.last::-webkit-scrollbar {
	display: none;
}

#block-menu-block-28 li:nth-child(8) {
	visibility: hidden;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}

#block-menu-block-28 li:nth-child(8)::-webkit-scrollbar {
	display: none;
}

#block-menu-block-28 li:nth-child(9) {
	display: none;
}


	.footer {
    border-top: none;
    margin-top: 0;
    padding-bottom:  0px;
    padding-top:  0px;
    background-color: #d61524;
    color: white;
}

.footer a{color:white;font-family: Mako,Helvetica,sans-serif,Arial}


#block-block-1 {
	margin-top: 40px;
}

footer .region.col-md-6 {
	padding: 0;
}

footer .row {
	margin: 0;
}

.footer #block-menu-block-26 .nav>li>a,
.footer #block-menu-block-26 .nav>li span.nolink,
.footer #block-menu-block-21 .nav>li>a,
.footer #block-menu-block-21 .nav>li span.nolink {
	font-family: 'Abel-Regular';
	text-transform: uppercase;
}

.footer_text {
	font-size: 16px;
	line-height: 19px;
	margin-left: 15px;
	margin-right: 15px;
}

#block-menu-block-14 .nav>li>a {
	text-transform: none;
}

.int-25 a,
#block-menu-block-15 a {
	font-family: 'Abel-Regular';
	font-size: 13px;
	text-transform: uppercase;
}

footer .row_f1 h2.block-title {
	margin-top: 0px;
	margin-bottom: 12px;
}

footer .row_f1 .block-title a {
	color: #ffffff;
	font: 20px/15px 'Abel-Regular', sans-serif;
	text-transform: uppercase;
}

footer .row_f1 .nav>li {
	margin: 5px 0;
}

footer .row_f1 .nav>li>a {
	color: #ffffff;
	font: 13px/15px Mako,Helvetica,sans-serif,Arial;
	text-transform: none;
	padding: 0;
}

footer .row_f1 .nav>li>a:hover {
	text-decoration: underline;
}

footer .row_f1 .nav>li>.nolink {
	font-weight: bold;
}

footer .row_f1 {
	padding-bottom: 20px;
}

#block-menu-block-8 {
	margin-top: 30px;
}

.row_f2 h2,
.row_f2 .h2 {
	margin-top: 0px;
	margin-bottom: 12px;
}

.row_f2 .titolo_footer a {
	color: #ffffff;
	font: 15px/20px 'Abel-Regular', sans-serif;
}

ul {
	padding: 0px;
	list-style-type: none;
}

.field-type-text-long ul {
	display: block;
	list-style-type: disc;
	padding-left: 40px;
}

.region-sub-footer ul {
	all: unset;
}

.region-sub-footer .field {
	line-height: 15px;
}

.row_f2 ul li {
	list-style-type: none;
	list-style-position: outside;
	margin: 5px 0;
	padding: 0px;
}

.row_f2 a {
	color: #ffffff;
	font: 13px/15px Mako,Helvetica,sans-serif,Arial;
	text-transform: none;
	padding: 0;
}

.row_f2 #block-views-target-blocchi-men-block-4 {
	clear: both;
}

.footer .row_f2 {
	padding-bottom: 20px;
}

.footer .region-footer .nav>li>a {
	padding: 10px 20px;
	position: relative;
}

.footer #block-menu-block-14 .nav>li {
	display: inline-block;
}

.footer #block-menu-block-14 .nav>li>a,
.footer #block-menu-block-14 .nav>li span.nolink {
	font-size: 15px;
	color: #000000;
	line-height: 15px;
	padding: 5px 0;
}

.footer #block-menu-block-14 .nav>li>a:after,
.footer #block-menu-block-14 .nav>li>span:after {
	content: "|";
	display: inline-block;
	padding: 0 2px 0 8px;
}

.footer #block-menu-block-14 .nav>li.last>a:after,
.footer #block-menu-block-14 .nav>li.last>span:after {
	content: "";
}

.hp-slider-title {
	color: #ffffff;
	font-size: 21px;
}

.hp-slider-title a {
	color: #ffffff;
}

.media-tablet-landscape .hp-slider-title a,
.media-tablet-desktop .hp-slider-title a,
.media-phone .hp-slider-title a {
	float: left;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
	height: auto;
	min-height: 120px;
	width: 100%;
	max-width: none;
}

.hp-slider-overlay {
	background: rgba(0, 0, 0, 0.33) none repeat scroll 0 0;
	color: #ffffff;
	min-height: 120px;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	text-align: right;
	padding: 20px;
}

.media-tablet-landscape .hp-slider-overlay,
.media-tablet-desktop .hp-slider-overlay,
.media-phone .hp-slider-overlay {
	text-align: left;
}

#block-menu-block-16 h2.block-title {
	margin-top: 40px;
	margin-bottom: 40px;
	font-size: 45px;
}

.menu-block-16 ul li {
	border-left: 1px solid #000000;
	margin-bottom: 25px;
	position: relative;
}

.menu-block-16 ul li a {
	font: 40px/35px Mako,Helvetica,sans-serif,Arial;
	color: #000000;
	padding: 5px 10px;
	height: 95px;
	text-transform: none;
	width: 100px;
}

.menu-block-16 ul li a:after,
.menu-block-16 ul li span:after {
	background: #d61524;
	bottom: 0;
	color: #e5c200;
	content: "";
	display: block;
	height: 8px;
	left: 9px;
	position: absolute;
	width: 85px;
}

#block-menu-block-16 li a[title="Economico statistica"]:after,
#block-menu-block-16 li span[title="Economico statistica"]:after {
	background: #e5c200;
}

#block-menu-block-16 li a[title="Giuridica"]:after,
#block-menu-block-16 li span[title="Giuridica"]:after {
	background: #005096;
}

#block-menu-block-16 li a[title="Scientifica"]:after,
#block-menu-block-16 li span[title="Scientifica"]:after {
	background: #00753b;
}

#block-menu-block-16 li a[title="Medica"]:after,
#block-menu-block-16 li span[title="Medica"]:after {
	background: #e63424;
}

#block-menu-block-16 li a[title="Sociologica"]:after,
#block-menu-block-16 li span[title="Sociologica"]:after {
	background: #f29400;
}

#block-menu-block-16 li a[title="Psicologica"]:after,
#block-menu-block-16 li span[title="Psicologica"]:after {
	background: #a4224b;
}

#block-menu-block-16 li a[title="Formazione"]:after,
#block-menu-block-16 li span[title="Formazione"]:after {
	background: #b82585;
}

.vocabulary-aree-disciplinari.view-mode-full .row>div {
	float: right;
}

.metrica-elenco {
	margin-bottom: 30px;
}

.metrica-elenco .label-above,
.metrica-label {
	background: rgb(167, 30, 59) none repeat scroll 0 0;
	font-size: 20px;
	padding: 8px 10px;
	color: #ffffff;
	font-family: "Abel-Regular", sans-serif;
}

.metrica-elenco ul {
	margin-left: 10px;
}

.metrica-elenco ul li {
	margin: 10px 0;
}

.metrica-elenco ul li a,
.metrica-elenco ul li a:hover,
.metrica-elenco ul li a:focus {
	color: #000;
	font-size: 16px;
	text-transform: uppercase;
}

.field-name-area-didattica-corsi {
	margin-bottom: 40px;
}

h3.titolo_corso {
	color: #000;
	font-size: 30px;
	margin: 0;
	padding: 10px 15px;
	background: #efefef;
}

.page-taxonomy-term-20 .ui-state-default,
.page-taxonomy-term-20 .ui-widget-content .ui-state-default,
.page-taxonomy-term-20 .ui-widget-header .ui-state-default,
.page-taxonomy-term-20 .ui-widget-content .ui-state-default a,
.page-taxonomy-term-20 .ui-widget-header .ui-state-default a,
.page-taxonomy-term-20 .metrica-elenco .label-above,
.page-taxonomy-term-20 .metrica-label {
	background-color: #e5c200;
	color: #000000;
}

.page-taxonomy-term-21 .ui-state-default,
.page-taxonomy-term-21 .ui-state-default a,
.page-taxonomy-term-21 .ui-state-default a:link,
.page-taxonomy-term-21 .ui-state-default a:visited,
.page-taxonomy-term-21 .ui-widget-content .ui-state-default,
.page-taxonomy-term-21 .ui-widget-header .ui-state-default,
.page-taxonomy-term-21 .ui-widget-content .ui-state-default a,
.page-taxonomy-term-21 .ui-widget-header .ui-state-default a,
.page-taxonomy-term-21 .metrica-elenco .label-above,
.page-taxonomy-term-21 .metrica-label {
	background-color: #005096;
	color: #fff;
}

.page-taxonomy-term-22 .ui-state-default,
.page-taxonomy-term-22 .ui-state-default a,
.page-taxonomy-term-22 .ui-state-default a:link,
.page-taxonomy-term-22 .ui-state-default a:visited,
.page-taxonomy-term-22 .ui-widget-content .ui-state-default,
.page-taxonomy-term-22 .ui-widget-header .ui-state-default,
.page-taxonomy-term-22 .ui-widget-content .ui-state-default a,
.page-taxonomy-term-22 .ui-widget-header .ui-state-default a,
.page-taxonomy-term-22 .metrica-elenco .label-above,
.page-taxonomy-term-22 .metrica-label {
	background-color: #00753b;
	color: #fff;
}

.page-taxonomy-term-23 .ui-state-default,
.page-taxonomy-term-23 .ui-state-default a,
.page-taxonomy-term-23 .ui-state-default a:link,
.page-taxonomy-term-23 .ui-state-default a:visited,
.page-taxonomy-term-23 .ui-widget-content .ui-state-default,
.page-taxonomy-term-23 .ui-widget-header .ui-state-default,
.page-taxonomy-term-23 .ui-widget-content .ui-state-default a,
.page-taxonomy-term-23 .ui-widget-header .ui-state-default a,
.page-taxonomy-term-23 .metrica-elenco .label-above,
.page-taxonomy-term-23 .metrica-label {
	background-color: #e63424;
	color: #fff;
}

.page-taxonomy-term-24 .ui-state-default,
.page-taxonomy-term-24 .ui-widget-content .ui-state-default,
.page-taxonomy-term-24 .ui-widget-header .ui-state-default,
.page-taxonomy-term-24 .ui-widget-content .ui-state-default a,
.page-taxonomy-term-24 .ui-widget-header .ui-state-default a,
.page-taxonomy-term-24 .metrica-elenco .label-above,
.page-taxonomy-term-24 .metrica-label {
	background-color: #f29400;
	color: #000000;
}

.page-taxonomy-term-25 .ui-state-default,
.page-taxonomy-term-25 .ui-state-default a,
.page-taxonomy-term-25 .ui-state-default a:link,
.page-taxonomy-term-25 .ui-state-default a:visited,
.page-taxonomy-term-25 .ui-widget-content .ui-state-default,
.page-taxonomy-term-25 .ui-widget-header .ui-state-default,
.page-taxonomy-term-25 .ui-widget-content .ui-state-default a,
.page-taxonomy-term-25 .ui-widget-header .ui-state-default a,
.page-taxonomy-term-25 .metrica-elenco .label-above,
.page-taxonomy-term-25 .metrica-label {
	background-color: #a4224b;
	color: #fff;
}

.page-taxonomy-term-26 .ui-state-default,
.page-taxonomy-term-26 .ui-state-default a,
.page-taxonomy-term-26 .ui-state-default a:link,
.page-taxonomy-term-26 .ui-state-default a:visited,
.page-taxonomy-term-26 .ui-widget-content .ui-state-default,
.page-taxonomy-term-26 .ui-widget-header .ui-state-default,
.page-taxonomy-term-26 .ui-widget-content .ui-state-default a,
.page-taxonomy-term-26 .ui-widget-header .ui-state-default a,
.page-taxonomy-term-26 .metrica-elenco .label-above,
.page-taxonomy-term-26 .metrica-label {
	background-color: #b82585;
	color: #fff;
}

#block-views-area-disciplinari-block .views-row {
	margin-bottom: 30px;
}

#block-views-area-disciplinari-block .views-row-last {
	margin-bottom: 0;
}

#overlay {
	position: absolute;
	top: 158px;
	left: 0;
	width: 100%;
	height: 390px;
	z-index: 100;
	background-color: rgba(230, 230, 230, 0.95);
	padding: 30px 20px;
	display: none;
}

.aree-disciplinari-box {
	color: white;
	float: left;
	height: 330px;
	width: 25%;
	max-width: 230px;
	font: 32px/1 Mako,Helvetica,sans-serif,Arial;
	text-align: right;
	position: relative;
}

.aree-disciplinari-box-title a {
	color: #ffffff;
	font-family: "Abel-Regular", sans-serif;
	text-decoration: underline;
}

.aree-disciplinari-box-prefix {
	padding: 30px 30px 0;
}

.aree-disciplinari-box-title {
	padding: 0 30px;
}

.aree-disciplinari-box-suffix {
	border-top: 1px solid;
	font-size: 20px;
	padding: 35px 30px 50px;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.economico-statistica {
	background-color: #E5C200;
}

.giuridica {
	background-color: #005096;
}

.scientifica {
	background-color: #00753B;
}

.medica {
	background-color: #E63424;
}

.sociologica {
	background-color: #F29400;
}

.psicologica {
	background-color: #A4224B;
}

.pedagogica {
	background-color: #B82585;
}

.formazione {
	background-color: #B82585;
}

#overlay .view-notizie-eventi {
	position: absolute;
	left: 230px;
	top: 270px;
	width: 75%;
	padding: 0 0 0 7.5%;
}

#overlay .view-notizie-eventi .views-row {
	padding: 0 5px;
}

#overlay .titolo_box_2 {
	font-weight: bold;
}

#overlay .taxonomy-term.vocabulary-aree-disciplinari {
	float: left;
	width: 75%;
	padding-left: 50px;
}

#overlay .field-name-field-numero {
	font-size: 50px;
	line-height: 50px;
	font-family: "Abel-Regular", serif;
}

#overlay .field-name-field-tipologia {
	font-size: 1.1em;
	font-family: "Abel-Regular", serif;
}

#overlay .field-name-field-sottotitolo {
	font-size: 1.1em;
}

#overlay .paragraphs-items-field-paragrafo-tipologie {
	width: 100%;
	float: left;
}

#overlay .field-name-field-paragrafo-tipologie>.field-items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

#overlay .field-name-field-paragrafo-tipologie>.field-items>.field-item {
	border-left: 3px solid;
	margin: 40px 0;
	padding: 6px 10px;
	width: 25%;
}

#overlay .numero_overlay {
	font-family: Mako,Helvetica,sans-serif,Arial;
	font-size: 40px;
	line-height: 1;
	padding-bottom: 0;
}

#overlay .metrica_overlay {
	font-family: "Abel-Regular";
	font-size: 33px;
	margin-top: 0;
	text-align: left;
}

#overlay .field-name-field-immagine-area {
	float: right;
}

#overlay .date-display-single {
	display: block;
	font-size: 15px;
}

#overlay .view-notizie-eventi a {
	color: #000000;
	font: 13px/13px "Abel-Regular", sans-serif;
}

.node-elementi-della-home-page.view-mode-full {
	margin: 60px -20px 40px;
}

.paragraphs-item-homebox {
	border-bottom: 1px solid #ffffff;
	position: relative;
	padding-bottom: 30px;
}

.paragraphs-item-homebox.bggray,
.paragraphs-item-homebox.bggray .homebox-content:after {
	background: #CFCFCF;
}

.paragraphs-item-homebox.bgred,
.paragraphs-item-homebox.bgred .homebox-content:after {
	background: #d61524;
	color: #ffffff;
}

.paragraphs-item-homebox.bgimg {
	background: #d61524;
	color: #ffffff;
	padding-bottom: 0;
}

.paragraphs-item-homebox.bgred a {
	color: #ffffff;
}

.paragraphs-item-homebox .button a {
	color: #000000;
	background-color: rgba(239, 239, 239, 0.8);
	background-image: url("/sites/all/themes/unimib/img/arrowgray.png");
}

.paragraphs-item-homebox.bgred .button a {
	color: #d61524;
	background-color: rgba(239, 239, 239, 0.8);
	background-image: url("/sites/all/themes/unimib/img/arrowred.png");
}

.paragraphs-item-homebox.bggray .button a {
	color: #000000;
	background-color: rgba(239, 239, 239, 0.8);
	background-image: url("/sites/all/themes/unimib/img/arrowgray.png");
}

.paragraphs-item-homebox.bgimg .button a {
	color: #ffffff;
	background-color: rgba(167, 30, 59, 0.8);
	background-image: url("/sites/all/themes/unimib/img/arrowwhite_space.png");
}

.paragraphs-item-homebox .file-video {
	padding: 110px 0 30px;
	position: relative;
	z-index: 2;
	text-align: center;
}

.paragraphs-item-homebox .file-video iframe {
	max-width: 100%;
	border: none;
}

.paragraphs-item-homebox.smallbox .file-video {
	padding: 10px 0;
}

.paragraphs-item-homebox .homebox-content {
	padding: 0 20px;
	width: 100%;
	line-height: 20px;
	overflow: hidden;
	position: relative;
}

.paragraphs-item-homebox .homebox-content p {
	margin-bottom: 20px;
}

.paragraphs-item-homebox .homebox-content p:last-child {
	margin-bottom: 0;
}

.paragraphs-item-homebox.bgimg .homebox-content {
	position: absolute;
	top: 0;
	max-height: unset;
	height: 100%;
	width: 100%;
}

.paragraphs-item-homebox.bgimg img {
	min-height: 100%;
	min-width: 100%;
	object-fit: cover;
	width: 100%;
}

.hititle .titolo_box_2 {
	background: #d61524 none repeat scroll 0 0;
	color: #ffffff;
	font-size: 32px;
	line-height: 40px;
	margin: 0 -20px;
	padding: 17px 20px;
	position: relative;
	top: 60px;
}

.paragraphs-item-homebox .homebox-list {
	margin: 20px 0 10px;
}

.paragraphs-item-homebox .homebox-list li {
	margin-bottom: 8px;
}

.quicktabs-tabs {
	position: absolute;
	top: -54px;
	padding-left: 0px;
	left: 0;
}

#block-quicktabs-blocco-home {
	position: relative;
}

#block-quicktabs-blocco-home ul.quicktabs-tabs li {
	border-left: 2px solid #f2f2f2;
	border-top: 2px solid #f2f2f2;
	border-right: 2px solid #f2f2f2;
	margin-right: 8px;
	padding: 0px;
	display: inline-block;
}

#block-quicktabs-blocco-home ul.quicktabs-tabs li.active {
	border-color: #d61524;
	background-color: #d61524;
}

#block-quicktabs-blocco-home ul.quicktabs-tabs li a {
	font-family: 'Abel-Regular';
	font-size: 25px;
	padding: 13px 8px 8px;
	color: #d61524;
	display: inline-block;
}

#block-quicktabs-blocco-home ul.quicktabs-tabs li.active a {
	color: #ffffff;
}

.bannernews .col-xs-12 {
	padding: 40px 15px 0;
}

.bannernews.view-notizie-eventi .views-field-field-data-evento-1 {
	height: 100px;
	width: 100px;
	background-color: #ffffff;
	border-bottom: 9px solid #000000;
	position: absolute;
	color: #000000;
	line-height: 22px;
}

.bannernews.view-notizie-eventi .views-field-field-data-evento-1 .start-date,
.bannernews.view-notizie-eventi .views-field-field-data-evento-1 .end-date {
	width: 0;
	height: 0;
	top: 0;
	border-style: solid;
}

.bannernews.view-notizie-eventi .views-field-field-data-evento-1 .start-date {
	border-width: 100px 100px 0 0;
	border-color: #ffffff transparent transparent transparent;
	color: #000000;
}

.bannernews.view-notizie-eventi .views-field-field-data-evento-1 .end-date {
	border-width: 0 0 100px 100px;
	border-color: transparent transparent #000000 transparent;
}

.date-display-double .start-date,
.date-display-double .end-date {
	position: absolute;
}

.date-display-double .start-date .data_box {
	font-size: 150% !important;
	text-align: left;
	margin-top: -90px;
	margin-left: 10px;
}

.date-display-double .start-date .data_box.cifre {
	left: 13px;
}

.date-display-double .start-date .data_box.cifra {
	left: 17px;
}

.date-display-double .start-date .giorno_box {
	font-size: 110% !important;
	text-align: left;
	margin-top: 2px;
	margin-left: 10px;
}

.date-display-double .end-date .data_box {
	font-size: 150% !important;
	color: #ffffff;
	position: relative;
	margin-top: 10px;
	bottom: -35px;
}

.date-display-double .end-date .data_box.cifre {
	right: 35px;
}

.date-display-double .end-date .data_box.cifra {
	right: 31px;
}

.date-display-double .end-date .giorno_box {
	color: white;
	font-size: 110% !important;
	position: relative;
	bottom: -35px;
	right: 35px;
	margin-top: 2px;
}

.bannernews .titolo,
.bannernews .sottotitolo {
	padding-left: 120px;
}

.bannernews .titolo a {
	font-size: 25px;
	font-family: 'Abel-Regular';
	color: #ffffff;
	line-height: 20px;
	text-transform: uppercase;
}

.bannernews .sottotitolo {
	font-size: 20px;
	line-height: 25px;
	min-height: 78px;
}

.bannernews .more-link a {
	max-width: 190px;
	padding: 11px 40px 11px 15px;
	display: block;
	margin: 30px auto;
	font-size: 17px;
	background-repeat: no-repeat;
	background-position: right 16px;
	font-family: 'Abel-Regular', sans-serif;
	color: #d61524;
	background-color: #efefef;
	background-color: rgba(239, 239, 239, 0.8);
	background-image: url("/sites/all/themes/unimib/img/arrowred.png");
}

.bannernews.grigio .more-link a {
	color: #000000;
	background-image: url("/sites/all/themes/unimib/img/arrowgrey.png");
}

.dcsns-twitter {
	display: none;
}

#block-jquery-social-stream-jquery-social-stream {
	position: relative;
	background: #F2F2F2;
	padding: 0 18px;
	max-width: 1280px;
	margin: 30px auto 0;
}

#block-jquery-social-stream {
	border-radius: 0;
	position: relative;
	background: none;
	padding: 0;
}

.follow-us-link {
	font: 13px/20px Abel-Regular, sans-serif;
	color: #d61524;
	top: 40px;
	right: 18px;
	position: absolute;
	text-decoration: underline;
}

#block-jquery-social-stream-jquery-social-stream h2 {
	font-size: 45px;
}

#block-jquery-social-stream-left-wrapper {
	width: 48px;
	position: absolute;
	z-index: 100;
	display: block;
	left: 18px;
}

#block-jquery-social-stream-wrapper {
	position: relative;
	padding-left: 59px;
	width: 100%;
}

#block-jquery-social-stream-left-wrapper ul.social-stream-jselector {
	list-style-type: none;
	margin: 0;
	padding: 0;
	background-color: #f2f2f2;
}

#block-jquery-social-stream-left-wrapper ul.social-stream-jselector li {
	padding-top: 8px;
	padding-bottom: 7px;
	position: relative;
	font: 155.5%/1.23 'Abel-Regular', sans-serif;
	height: 48px;
}

#block-jquery-social-stream-left-wrapper ul.social-stream-jselector li:hover {
	cursor: pointer;
}

#block-jquery-social-stream-jquery-social-stream .section-title,
#block-jquery-social-stream-jquery-social-stream .section-user,
#block-jquery-social-stream-jquery-social-stream .twitter-user,
#block-jquery-social-stream-jquery-social-stream .section-share,
#block-jquery-social-stream-jquery-social-stream .section-intro,
#block-jquery-social-stream-jquery-social-stream .stream li>a {
	display: none;
}

#block-jquery-social-stream-left-wrapper .socicon {
	visibility: hidden;
	position: absolute;
	display: block;
	font-size: 30px;
	color: white;
	width: 48px;
	height: 48px;
	top: 0;
	text-align: center;
	right: 0px;
	padding-top: 10px;
}

#block-jquery-social-stream-left-wrapper .socicon-facebook {
	background-color: #3B5998;
}

#block-jquery-social-stream .stream .dcsns-facebook .section-text a {
	color: #3B5998;
}

#block-jquery-social-stream-left-wrapper .socicon-twitter {
	background-color: #1B778B;
}

#block-jquery-social-stream .stream .dcsns-twitter .section-text a {
	color: #1B778B;
}

#block-jquery-social-stream-left-wrapper ul.social-stream-jselector li:before {
	content: "";
	position: absolute;
	border-right: 15px solid;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

#block-jquery-social-stream-left-wrapper ul.social-stream-jselector li:hover::before {
	border-width: 0;
}

#block-jquery-social-stream-left-wrapper ul.social-stream-jselector li.f-facebook:before {
	border-color: #3B5998;
}

#block-jquery-social-stream-left-wrapper ul.social-stream-jselector li.f-twitter:before {
	border-color: #1B778B;
}

#block-jquery-social-stream-left-wrapper li.f-twitter.active:before,
#block-jquery-social-stream-left-wrapper li.f-facebook.active:before {
	border-width: 0;
}

#block-jquery-social-stream-left-wrapper li.f-twitter.active,
#block-jquery-social-stream-left-wrapper li.f-twitter:hover {
	color: #1B778B;
}

#block-jquery-social-stream-left-wrapper li.f-facebook.active,
#block-jquery-social-stream-left-wrapper li.f-facebook:hover {
	color: #3B5998;
}

#block-jquery-social-stream-jquery-social-stream .dcsns ul,
#block-jquery-social-stream-jquery-social-stream .dcsns-content,
#block-jquery-social-stream-jquery-social-stream .dcsns li {
	border-radius: 0;
	width: 100%;
	background: none;
}

.dcsns-content {
	margin-bottom: 30px;
}

#block-jquery-social-stream-jquery-social-stream .stream li {
	background: none;
	border: none;
	border-radius: 0;
	float: left;
	font-size: 100%;
	line-height: 1.11;
	padding: 0;
}

#block-jquery-social-stream-jquery-social-stream .social_title {
	display: none;
}

#block-jquery-social-stream-jquery-social-stream .stream li.dcsns-twitter {
	margin-bottom: 200px;
}

#block-jquery-social-stream-jquery-social-stream .stream li .inner {
	padding: 0;
}

#block-jquery-social-stream-jquery-social-stream .stream li .section-thumb {
	float: none;
	margin: 0;
	max-width: 100%;
	height: 115px;
	overflow: hidden;
	display: block;
}

#block-jquery-social-stream-jquery-social-stream .stream li .section-thumb img {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	max-width: 100%;
	padding: 0;
	height: auto;
	width: 100%;
	min-height: 115px;
}

#block-jquery-social-stream-jquery-social-stream .stream li .section-text {
	display: block;
	font-family: Mako,Helvetica,sans-serif,Arial;
	line-height: 1.11;
	margin-bottom: 0;
	font-size: 20px;
}

#block-jquery-social-stream-jquery-social-stream .stream li.dcsns-facebook .section-text {
	padding: 5px 10px 0;
	height: 72px;
	overflow: hidden;
	margin-bottom: 100px;
}

#block-jquery-social-stream-jquery-social-stream .twitter-text {
	padding: 5px 5px 5px 0;
	display: block;
}

#block-jquery-social-stream-jquery-social-stream .stream li.dcsns-twitter .section-text img {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	border: medium none;
	margin: 0 0 5px;
	max-width: 210px;
	padding: 0;
}

.dcsns-toolbar {
	border-radius: 0;
	padding: 0;
	position: absolute;
	top: 80px;
	height: 35px;
	width: 100%;
}

.dcsns .controls {
	position: static;
}

.dcsns .controls a {
	float: none;
	height: unset;
	width: unset;
	margin: 0;
	background-color: rgba(0, 0, 0, 0.5);
	background-image: none;
	display: block;
	height: 35px;
	position: absolute;
	width: 35px;
	bottom: 0;
}

.dcsns .controls a.prev {
	left: 0;
}

.dcsns .controls a.next {
	right: 0;
}

.dcsns .controls a:before {
	cursor: pointer;
	top: 8px;
	position: absolute;
	width: 100%;
	text-align: center;
}

.dcsns .controls a.next:before {
	right: 0;
}

.dcsns .controls a:before {
	content: url("/sites/all/themes/unimib/img/arrowbackwhite.png");
}

.dcsns .controls a.next:before {
	content: url("/sites/all/themes/unimib/img/arrowwhite.png");
}

#block-views-banner-block {
	position: relative;
}

#block-views-banner-block .views-row {
	padding: 0;
	width: 24.25%;
	margin: 60px 1% 20px 0;
}

#block-views-banner-block .views-row-last {
	margin-right: 0;
}

#block-views-banner-block .views-field-title a {
	display: block;
	color: #ffffff;
	text-indent: -9999px;
	padding: 10px 8px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#block-views-banner-block .views-field-title a:hover {
	text-decoration: none;
	color: #ffffff;
	text-align: right;
	text-indent: 0px;
	padding: 10px 8px;
	position: absolute;
	background: #000000;
	background: rgba(0, 0, 0, 0.75);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#block-views-banner-block .views-field-title a .titolo_banner {
	font: 30px/28px 'Abel-Regular', sans-serif;
	display: block;
}

#block-views-banner-block .views-field-title a .sottotitolo_banner {
	display: block;
}

#block-views-notizie-eventi-block-5 {
	position: relative;
}

#block-views-notizie-eventi-block-5 .block-title {
	color: #d61524;
	font-size: 30px;
	margin: 0;
	position: absolute;
	top: -44px;
}

.menu-profili {
	background-color: #d61524;
	padding-bottom: 10px;
}

.menu-profili .button>a {
	font-family: Mako,Helvetica,sans-serif,Arial;
}

.menu-profili .button>a:hover {
	font-family: "Abel-Regular", sans-serif;
	text-decoration: none;
}

.hp-link {
	background: #e5e5e5;
	padding: 30px 20px;
}

.hp-link.profilo {
	margin-bottom: 30px;
}

.hp-link h2 {
	margin: 0 0 30px;
}

.lista-arrow,
.lista {
	list-style-type: none;
	margin: 0 0 0 20px;
	position: relative;
}

.lista {
	margin: 0;
}

.lista-arrow>li {
	margin-bottom: 30px;
}

.lista-arrow>li.last,
.lista-arrow>li.views-row-last {
	margin-bottom: 0;
}

.lista>li {
	margin-bottom: 18px;
}

.lista>li a {
	font-size: 22px;
}

.lista-arrow>li:nth-child-last,
.lista>li:nth-child-last {
	margin-bottom: 0;
}

.lista-arrow>li:before {
	content: '>';
	left: -15px;
	position: absolute;
}

.lista-arrow>li>a {
	text-decoration: underline;
}

h2.titolo_pagina {
	margin-top: 0;
}

paragraphs-item .aprichiudi-opzione-parent .field-name-field-testo-paragrafo .field-items {}

ul.lista-allegati {
	padding-left: 0;
}

.paragraphs-item-testo .field-name-field-foto-paragrafo img {
	margin: 5px auto 10px;
}

.title-underline-border {
	margin-bottom: 10px;
}

.field-name-ancora p {
	margin: 0px;
}

.field-name-ancora {
	diaplay: block !important;
}

.paragraphs-item-testo.paragrafo-citazione .field-name-field-testo-paragrafo p:before {
	content: "\"";
	left: -10px;
	line-height: 30px;
	position: relative;
	top: 15px;
	font-size: 58px;
	font-style: italic;
	font-family: 'Abel-Regular', sans-serif;
}

.paragraphs-item-testo.paragrafo-citazione .field-name-field-testo-paragrafo p:after {
	content: "\"";
	position: absolute;
	bottom: -27px;
	font-size: 58px;
	font-style: italic;
	font-family: 'Abel-Regular', sans-serif;
}

.paragraphs-item-testo.paragrafo-citazione .field-name-field-testo-paragrafo {
	color: #d61524;
	font-family: "Cursive", serif;
	font-size: 20px;
	line-height: 1.3;
	font-style: italic;
	position: relative;
}

.col-sm-6 .title-underline-border {
	margin-top: 15px;
}

.paragraphs-item-link-correlati,
.paragraphs-item-testo-due-colonne,
.paragraphs-item-immagine-link,
.paragraphs-item-testo,
.paragraphs-item-documenti,
.paragraphs-item-immagine-contenuto,
.paragraphs-item-circolari-comunicati,
.paragraphs-item-photogallery,
.paragraphs-item-embedding,
.paragraphs-item-scheda {
	margin-bottom: 10px;
	margin-top: 20px;
}

.aprichiudi-opzione-parent {
	margin-bottom: 2px;
	margin-top: 0px;
}

.aprichiudi-opzione-parent .titolo_paragrafo {
	margin-bottom: 0px;
	margin-top: 0px;
}

.aprichiudi-opzione-parent .ui-state-active {}

.paragraphs-item .aprichiudi-opzione-parent ul.lista-allegati {
	padding-left: 20px;
}

.paragraphs-item .aprichiudi-opzione-parent .field-name-field-foto-paragrafo,
.paragraphs-item .aprichiudi-opzione-parent .field-name-field-testo-paragrafo {
	padding: 10px 20px;
}

.paragraphs-item .aprichiudi-opzione-parent .paragraphs-items-field-paragrafo-corr,
.paragraphs-item .aprichiudi-opzione-parent .circolari-data {
	margin-left: 20px;
}

.paragraphs-item .aprichiudi-opzione-parent .paragraphs-items-field-paragrafo-corr li.last {
	margin-bottom: 20px;
}

.field-name-pubblicazione {
	margin-top: 20px;
}

ul.correlazioni {
	list-style-type: none;
}

.circolari-data {
	font: 100%/1.85 'Abel-Regular', sans-serif;
	background: #cfcfcf;
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
	width: auto;
	margin-top: 20px;
	margin-bottom: 10px;
}

.paragraphs-item-documenti ul.lista-allegati {
	list-style: inside none none;
}

.separatore {
	width: 64px;
	content: " ";
	border-bottom: 9px solid #d61524;
	margin-bottom: 30px;
}

.paragraphs-item-link-correlati li {
	list-style-type: none;
	margin: 14px 0;
	padding-left: 15px;
}

.paragraphs-item-link-correlati li a {
	font: 18px/18px 'Abel-Regular', sans-serif;
	position: relative;
}

.paragraphs-item-link-correlati li a::before {
	content: ">";
	font: 13px/13px "Abel-Regular", sans-serif;
	left: -10px;
	position: absolute;
	top: 5px;
}

.paragraphs-item-immagine-link li {
	font: 18px/18px 'Abel-Regular', sans-serif;
	list-style: none;
	background: url(/sites/all/themes/unimib/img/black_arrow.png) no-repeat;
	background-position: 0px 2px;
	box-sizing: border-box;
	margin-bottom: 10px;
}

.paragraphs-item-immagine-link li .field-item {
	margin-left: 25px;
}

.paragraphs-item-immagine-link li a {
	color: #000000;
	display: block;
	padding: 0 0 0 20px;
}

.paragraphs-item-immagine-contenuto img {
	width: 100%;
}

.paragraphs-items-field-paragrafo .paragraphs-item-immagine-contenuto .row {
	margin: 0;
}

.paragraphs-items-field-paragrafo .paragraphs-item-immagine-contenuto .row>.col-sm-12 {
	padding: 0;
}

.fascia_didascalia {
	background: #d61524 url("/sites/all/themes/unimib/img/arrowwhite_space.png") no-repeat scroll 12px 22px;
	padding: 21px 40px;
	width: 100%;
	color: #ffffff;
}

.fascia_didascalia a {
	font-size: 15px;
	line-height: 15px;
	color: #ffffff;
	display: block;
}

.embed-responsive-item iframe {
	max-width: 100%;
}

.slick__arrow {
	position: absolute;
	top: 50%;
	width: 100%;
	height: 0;
}

.slick__arrow button {
	background-color: #cfcfcf;
	border: none;
	color: transparent;
	font-size: 16px;
	overflow: hidden;
	display: block;
	height: 50px;
	width: 50px;
	position: relative;
	top: -25px;
	float: left;
}

.slick__arrow button.gallery-next,
.slick__arrow button.slick-next {
	float: right;
}

.gallery-next::after,
.slick-next::after {
	content: url(/sites/all/themes/unimib/img/arrowred.png);
	position: relative;
	left: 7px;
	top: -5px;
}

.gallery-prev:after,
.slick-prev:after {
	content: url(/sites/all/themes/unimib/img/arrowbackred.png);
	position: relative;
	left: -7px;
	top: -5px;
}

.media:hover .media-icon--lightbox {
	opacity: 0;
	visibility: hidden;
	display: none;
}

.slick {
	position: relative;
}

.slick-track {
	height: 153px;
}

.slick--skin--fullwidth .slide__media img {
	height: 153px;
	width: 460px;
	min-width: 100%;
}

.webform-client-form .form-group {
	background: #f2f2f2 none repeat scroll 0 0;
	border-bottom: 1px solid #fff;
	margin-bottom: 0;
	padding: 20px;
}

.form-inline .form-control {
	display: block;
	float: right;
}

.titolo_scheda {
	font-size: 45px;
	line-height: 0.9;
}

.node-type-evento .view-mode-full .field-name-field-sottotitolo,
.node-type-avvisi .view-mode-full .field-name-field-sottotitolo,
.node-type-article .view-mode-full .field-name-field-sottotitolo {
	margin-bottom: 30px;
	font-style: italic;
}

.rubrica_block {
	position: relative;
	margin-bottom: 40px;
}

.rubrica_block .view-filters {
	background-color: #f2f2f2;
	padding: 80px 20px 20px;
}

.rubrica_block h2 {
	position: absolute;
	left: 20px;
	top: 40px;
	margin: 0;
}

.rubrica_block label {
	font-weight: normal;
	margin-right: 5px;
	margin-bottom: 0;
	position: relative;
	top: -5px;
	width: 55px;
}

.rubrica_block .views-widget {
	margin: 0;
	padding: 0;
	display: inline-block;
}

.rubrica_block .views-submit-button {
	display: block;
	width: 100%;
}

.rubrica_block .views-submit-button button {
	background-color: #d61524;
	color: white;
	font-family: 'Abel-Regular', sans-serif;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 10px;
	padding-right: 100px;
	font-size: 18px;
	position: relative;
}

.rubrica_block .views-submit-button button:hover,
.rubrica_block .views-submit-button button:focus,
.rubrica_block .views-submit-button button:active,
.rubrica_block .views-submit-button button.hover,
.rubrica_block .views-submit-button button.focus,
.rubrica_block .views-submit-button button.active {
	background-color: #d61524;
}

.rubrica_block .views-submit-button button::after {
	content: url(/sites/all/themes/unimib/img/arrowwhite_space.png);
	position: absolute;
	top: 10px;
	right: 0px;
}

.rubrica_block .view-empty,
.rubrica_block .view-content {
	display: table;
	width: 100%;
	margin: 40px 0;
}

.table-like-row {
	background-color: #f7f7f7;
	border-bottom: 1px solid #ddd;
}

.views-row-even.table-like-row {
	background-color: #fdfdfd;
}

.table-like-row:hover,
.views-row-even.table-like-row:hover {
	background: #ffffff;
}

.table-like-head {
	font-family: 'Abel-Regular', sans-serif;
	display: none;
	background-color: #eeeeee;
}

.table-like-head span {
	padding-top: 5px;
	padding-bottom: 5px;
	line-height: 1.42857;
	vertical-align: bottom;
	border-bottom: 2px solid #ddd;
}

.table-like-row {
	padding: 20px 0;
}

.table-like-cell {
	float: none;
	padding-top: 5px;
	padding-bottom: 5px;
	line-height: 1.42857;
	vertical-align: top;
}

.table-like-cell.field-name {
	font-size: 21px;
	font-family: 'Abel-Regular', sans-serif;
}

.no_link_112018,
.no_link_112082 {
	pointer-events: none;
	cursor: default;
}

.field-name-alberostrutture ul.menu>li>a {
	padding: 15px 20px;
	color: #ffffff;
	background-color: #d61524;
	margin-top: 20px;
	text-transform: uppercase;
	font-family: 'Abel-Regular', sans-serif;
	font-size: 25px;
	display: block;
}

.field-name-alberostrutture ul.menu>li>ul>li>a {
	display: block;
	font-family: Abel-Regular;
	list-style-type: none;
	margin: 20px 20px 0;
}

.field-name-alberostrutture ul.menu>li>ul>li ul {
	list-style-position: inside;
	list-style-type: square;
	margin: 0;
}

.field-name-alberostrutture ul.menu>li>ul>li>ul>li ul {
	margin: 15px 20px;
	list-style-type: circle;
}

.field-name-alberostrutture ul.menu>li>ul>li>ul>li>ul>li ul {
	list-style-type: disc;
}

.field-name-alberostrutture ul.menu>li>ul>li>ul>li {
	background-color: #fdfdfd;
	border-bottom: 2px solid #c2c2c2;
	padding: 20px;
}

.field-name-alberostrutture ul.menu>li>ul>li>ul>li>ul li {
	margin: 5px 0;
}

.field-name-alberostrutture a {
	color: #000000;
}

.group-responsabili {
	padding: 0 20px;
}

.group-responsabili .view-id-incarico .views-field-nome-esteso {
	margin-bottom: 8px;
}

.group-responsabili .view-id-incarico .views-row {
	margin-bottom: 15px;
}

.ugov-unitaorganizzativa .ui-state-default,
.ugov-unitaorganizzativa .ui-widget-content .ui-state-default,
.ugov-unitaorganizzativa .ui-widget-header .ui-state-default {
	background: #ffffff;
	border: none;
	color: #000;
	font-size: 30px;
}

.ugov-unitaorganizzativa .ui-state-default::after {
	left: 150px;
	font-family: 'Abel-Regular', sans-serif;
	font-size: 20px;
}

.ugov-unitaorganizzativa .ui-accordion .ui-accordion-content {
	padding: 0px 20px;
}

.ui-widget-content .view-ugov-view-unitaorganizzativa a {
	color: #d61524;
}

.group-schede .table {
	margin-bottom: 0px;
}

.group-schede tr {
	background-color: #f2f2f2 !important;
}

.group-schede td.field-label {
	width: 30%;
	text-transform: lowercase;
}

.group-schede td.field-label:first-letter {
	text-transform: uppercase;
}

.group-schede .table>tbody>tr>td {
	padding: 0px;
	line-height: 1;
	vertical-align: top;
	border-top: 0px;
}

.group-schede .table-responsive {
	overflow-x: unset;
}

.group-schede .field-name-capo-area .field-items {
	width: 100%;
}

.scheda.gray {
	background: #f2f2f2;
}

.scheda>.field {
	padding: 20px;
	border-bottom: 2px solid #C2C2C2;
}

.scheda>.field .field-label {
	font-weight: normal;
	font-family: 'Abel-Regular', sans-serif;
}

.scheda>.field-label-inline .field-label {
	width: 100%;
	margin-bottom: 10px;
}

.scheda>.field-label-inline .field-items {
	width: 70%;
}

.ugov-persona ul {
	list-style-type: none;
}

.groupbox.redtitle h3 {
	margin: 0 0 30px;
}

.groupbox.redtitle h3 {
	color: #d61524;
}

.groupbox.pad {
	padding: 0 20px;
}

.groupbox.gray {
	background: #F2F2F2;
	padding: 20px;
}

.groupbox .view-content h3 {
	font-size: 18px;
	margin: 0 0 10px;
	font-family: Mako,Helvetica,sans-serif,Arial;
}

.groupbox .view-content .boxlist {
	margin-bottom: 20px;
}

.groupbox .view-content .boxlist:nth-child-last {
	margin-bottom: 0;
}

.groupbox .view-content li {
	margin-bottom: 10px;
}

.groupbox.didattica .view-content li a {
	color: #000000;
}

.linkbox a:before {
	content: ">";
	left: -5px;
	position: relative;
}

.linkbox a {
	background: #f2f2f2 none repeat scroll 0 0;
	color: #000000;
	display: block;
	font-family: "Abel-Regular", sans-serif;
	font-size: 17px;
	padding: 11px 0 11px 20px;
	text-transform: none;
	width: 100%;
}

.field-name-ugov-contatto-email p {
	margin: 0;
}

.ugov-persona .field-name-upload-links {
	margin-top: 20px;
}

.ugov-persona .ugov-persona-upload {
	display: block;
	font: 91.1%/1.0 "Abel-Regular", sans-serif;
	background: #d61524 none repeat scroll 0 0;
	width: 100%;
	color: #ffffff;
	padding: 10px;
	margin-bottom: 10px;
}

.ugov-persona a.ugov-persona-upload {
	background-image: url("/sites/all/themes/unimib/img/upload.png");
	background-repeat: no-repeat;
	background-position: 94% 6px;
	background-attachment: scroll;
	background-color: #d61524;
	padding: 10px 40px 10px 10px;
}

.spazipersona-item {
	padding-bottom: 10px;
}

#legenda {
	border: 2px solid #dadada;
	padding: 0 10px 10px;
	margin-bottom: 20px;
}

#legenda .h3 {
	margin-top: 10px;
}

#legenda .titleblock {
	font-size: 40px;
	line-height: 40px;
	margin-bottom: 25px;
}

#legenda ul {
	list-style-type: none;
}

.filtri-elenco-corsi {
	background: #f2f2f2;
	margin-bottom: 30px;
	padding: 20px;
	position: relative;
}

.filtri-elenco-corsi h3 {
	margin-top: 0;
	color: #d61524;
}

.filtri-elenco-corsi .inner-wrapper {
	max-width: 320px;
}

.filtri-elenco-corsi .filtro-checkbox {
	width: 50%;
	float: left;
	margin-bottom: 15px;
}

.filtro-select {
	position: relative;
	clear: both;
}

.filtro-select>div {
	background: #ffffff;
}

.filtro-select select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-color: #E2E2E2;
	cursor: pointer;
	position: relative;
	background: none;
	z-index: 1;
}

.filtro-select:after {
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-top: 12px solid #000;
	clear: both;
	content: "";
	height: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 0;
}

.filtro-submit button {
	background-position: right 15px;
	font-size: 18px;
	margin: 30px 0 0;
	padding: 10px 30px 10px 20px;
	width: 120px;
}

.filtro-submit button {
	text-align: left;
}

.filtro-submit .icon.glyphicon {
	display: none;
}

.offertaview.aree .view-grouping-header {
	background: none;
	margin-bottom: 2px;
	padding: 0;
}

.offertaview.aree .view-grouping-header::after {
	top: 22px;
}

.offertaview.aree .nested-accordion::after {
	top: 35px;
}

.offertaview.aree .view-grouping-header>div {
	padding: 15px 60px 15px 20px;
	border-bottom: 1px solid #000000;
}

.offertaview.aree .view-grouping-header>div:after {
	content: " ";
	width: 64px;
	height: 9px;
	background-color: #A71D3E;
	display: block;
	margin-top: 15px;
}

.offertaview.aree .view-grouping .view-grouping-content {
	padding: 10px 0;
}

.offertaview.aree .view-grouping-content .ui-state-default {
	padding: 0;
	margin-bottom: 0;
}

.offertaview.aree .view-grouping-content .ui-state-default:after,
.offertaview.aree .view-grouping-content .ui-state-active:after {
	display: none;
	content: "";
}

.offertaview.aree .view-grouping-content .ui-state-default a {
	padding: 15px 20px 15px 20px;
	font-family: Mako,Helvetica,sans-serif,Arial;
	font-size: 17px;
}

.offertaview.aree .view-grouping-content .ui-state-default .area {
	padding-left: 20px;
	padding-right: 200px;
	position: relative;
}

.offertaview.aree .view-grouping-content .ui-state-default .areaextra {
	display: block;
	float: right;
	position: absolute;
	right: 50px;
	top: -2px;
}

.offertaview.aree .view-grouping-content .ui-state-default .areaextra>span {
	background: #ffffff;
	margin: 0 5px;
	padding: 2px 12px 4px;
	font-size: 15px;
	display: inline-block;
}

.offertaview .view-grouping-content .ui-state-default .area:after {
	content: "+";
	background-color: #A71D3E;
	font-size: 25px;
	height: 25px;
	line-height: 1;
	padding: 0 6px;
	top: -3px;
	position: absolute;
	right: 20px;
	color: #ffffff;
	cursor: pointer;
}

.offertaview .view-grouping-content .ui-state-active .area:after {
	content: "-";
	top: -3px;
	padding: 0 7.5px;
}

.offertaview .view-grouping-header>div.area-20:after,
.offertaview .view-grouping-content .ui-state-default .area-20:after {
	background-color: #e5c200;
}

.offertaview .view-grouping-header>div.area-21:after,
.offertaview .view-grouping-content .ui-state-default .area-21:after {
	background-color: #005096;
}

.offertaview .view-grouping-header>div.area-22:after,
.offertaview .view-grouping-content .ui-state-default .area-22:after {
	background-color: #00753b;
}

.offertaview .view-grouping-header>div.area-23:after,
.offertaview .view-grouping-content .ui-state-default .area-23:after {
	background-color: #e63424;
}

.offertaview .view-grouping-header>div.area-24:after,
.offertaview .view-grouping-content .ui-state-default .area-24:after {
	background-color: #f29400;
}

.offertaview .view-grouping-header>div.area-25:after,
.offertaview .view-grouping-content .ui-state-default .area-25:after {
	background-color: #a4224b;
}

.offertaview .view-grouping-header>div.area-26:after,
.offertaview .view-grouping-content .ui-state-default .area-26:after {
	background-color: #b82585;
}

.offertaview.dipartimenti .view-grouping-header {
	font-size: 20px;
}

.offertaview.dipartimenti .view-grouping-header::after,
.offertaview.dipartimenti .ui-state-default::after {
	top: 12px;
}

.offertaview.dipartimenti .nested-accordion::after,
.offertaview.dipartimenti .ui-state-active::after {
	top: 22px;
}

.offertaview.dipartimenti .views-row a {
	color: #d61524;
	margin-bottom: 5px;
	display: block;
}

#ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.accordion-oembed-view>.view-content>h3.ui-accordion-header {
	background-color: #d61524;
}

.area-20 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-20 #gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #e5c200;
}

.area-21 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-21 #gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #005096;
}

.area-22 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-22 #gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #00753b;
}

.area-23 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-23 #gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #e63424;
}

.area-24 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-24 #gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #f29400;
}

.area-25 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-25 #gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #a4224b;
}

.area-26 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-26 #gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #b82585;
}

#ugov-of-cds-ugov-accordion>h3.ui-accordion-header a,
.accordion-oembed-view>.view-content>h3.ui-accordion-header a,
.accordion-oembed-view>.view-content>h3.ui-accordion-header {
	font-size: 25px;
	line-height: 30px;
	color: #ffffff;
}

#ugov-of-cds-ugov-accordion>h3.ui-accordion-header:after,
.accordion-oembed-view>.view-content>h3.ui-accordion-header:after {
	color: #ffffff;
	font-size: 28px;
}

.area-20 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-20 #gruppo-docenti-wrapper .views-field.views-field-id-ab,
.area-20 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header:after,
.area-20 .accordion-oembed-view>.view-content>h3.ui-accordion-header:after,
.area-20 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header a,
.area-20 .accordion-oembed-view>.view-content>h3.ui-accordion-header a,
.area-20 .accordion-oembed-view>.view-content>h3.ui-accordion-header,
.area-20 #gruppo-docenti-wrapper .views-field.views-field-id-ab a {
	color: #000000;
}

.area-24 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header,
.area-24 #gruppo-docenti-wrapper .views-field.views-field-id-ab,
.area-24 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header:after,
.area-24 .accordion-oembed-view>.view-content>h3.ui-accordion-header:after,
.area-24 #ugov-of-cds-ugov-accordion>h3.ui-accordion-header a,
.area-24 .accordion-oembed-view>.view-content>h3.ui-accordion-header a,
.area-24 .accordion-oembed-view>.view-content>h3.ui-accordion-header,
.area-24 #gruppo-docenti-wrapper .views-field.views-field-id-ab a {
	color: #000000;
}

#ugov-of-cds-ugov-accordion.ui-accordion .ui-accordion-content {
	padding: 0;
}

#ugov-of-cds-ugov-accordion.ui-accordion .ui-accordion-content>.field {
	background: #F2F2F2;
	border-color: #C3C3C3;
	border-style: solid;
	border-width: 1px 0 1px 0;
}

#ugov-of-cds-ugov-accordion.ui-accordion .ui-accordion-content>.field>.field-label {
	font-weight: normal;
	font-family: 'Abel-Regular', sans-serif;
	padding: 15px 15px 0;
	width: 100%;
	font-size: 20px;
}

#ugov-of-cds-ugov-accordion.ui-accordion .ui-accordion-content>.field>.field-items {
	padding: 15px 15px 5px;
	font-size: 20px;
}

.field-name-pds h3.ui-accordion-header {
	padding: 10px;
}

.field-name-pds h3.ui-accordion-header a {
	font-size: 25px;
	font-family: Mako,Helvetica,sans-serif,Arial;
}

#ugov-of-cds-ugov-accordion.ui-accordion .field-name-pds .ui-accordion-content {
	background: none;
	padding: 10px;
}

.field-name-pds .ui-accordion-content .views-row {
	margin: 5px 0;
}

#gruppo-docenti-wrapper {
	margin-top: 40px;
	background-color: #f2f2f2;
	padding: 20px 20px 0;
}

#gruppo-docenti-wrapper>div {
	padding-bottom: 20px;
}

#gruppo-docenti-wrapper span {
	font: 30px/30px 'Abel-Regular', sans-serif;
}

#gruppo-docenti-wrapper .views-field.views-field-id-ab {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 10px;
}

#gruppo-docenti-wrapper .docenti-intro {
	font-size: 15px;
	padding-left: 10px;
	padding-right: 10px;
}

#gruppo-docenti-wrapper .views-field.views-field-id-ab {
	background-color: #d61524;
}

#gruppo-docenti-wrapper .views-field.views-field-id-ab a {
	display: block;
	color: white;
}

#gruppo-docenti-wrapper .col {
	margin-bottom: 30px;
}

.link-al-corso {
	padding: 20px;
	position: relative;
	background: #f2f2f2;
}

.link-al-corso .views-row {
	background: url(/sites/all/themes/unimib/img/logoarea.png) no-repeat 0 0;
	background-size: 100px 100px;
}

.link-al-corso .views-row.area-20 {
	background-image: url(/sites/all/themes/unimib/img/economico_statistica_logo.png);
}

.link-al-corso .views-row.area-21 {
	background-image: url(/sites/all/themes/unimib/img/giuridica_logo.png);
}

.link-al-corso .views-row.area-22 {
	background-image: url(/sites/all/themes/unimib/img/scientifica_logo.png);
}

.link-al-corso .views-row.area-23 {
	background-image: url(/sites/all/themes/unimib/img/medica_logo.png);
}

.link-al-corso .views-row.area-24 {
	background-image: url(/sites/all/themes/unimib/img/sociologica_logo.png);
}

.link-al-corso .views-row.area-25 {
	background-image: url(/sites/all/themes/unimib/img/psicologica_logo.png);
}

.link-al-corso .views-row.area-26 {
	background-image: url(/sites/all/themes/unimib/img/pedagogica_logo.png);
}

.link-al-corso .views-field-nome-esteso {
	margin-left: 120px;
	min-height: 100px;
	padding-top: 10px;
}

.link-al-corso .button {
	margin: 20px 0;
}

.nomepersona {
	font-size: 25px;
	line-height: 1;
	padding: 10px 0;
	margin-bottom: 30px;
	position: relative;
}

.nomepersona:after {
	background: #d61524;
	bottom: -10px;
	content: " ";
	display: block;
	height: 10px;
	left: 0;
	position: absolute;
	width: 80px;
}

ul.menu-corsi-sx {
	margin: 30px 0;
	border: 1px solid #d61524;
	padding-bottom: 15px;
}

ul.menu-corsi-sx a,
ul.menu-corsi-sx span {
	position: relative;
	display: block;
	color: #d61524;
}

ul.menu-corsi-sx>li>a {
	background: #d61524 none repeat scroll 0 0;
	color: #ffffff;
	font-family: 'Abel-Regular', sans-serif;
	font-size: 20px;
	line-height: 1;
	padding: 15px 30px;
}

ul.menu-corsi-sx>li>ul>li>a,
ul.menu-corsi-sx>li>ul>li>span {
	padding: 15px 30px 0 15px;
}

ul.menu-corsi-sx>li>ul>li>ul li a,
ul.menu-corsi-sx>li>ul>li>ul li span {
	padding: 15px 30px 0 15px;
}

ul.menu-corsi-sx li.expanded.active-trail>ul>li a.last,
ul.menu-corsi-sx li.expanded.active-trail>ul>li span.last {
	padding-bottom: 0;
}

ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span {
	font-size: 25px;
	line-height: 1;
	padding: 10px 60px 0 15px;
	font-family: 'Abel-Regular', sans-serif;
}

ul.menu-corsi-sx>li>ul>li.expanded.active-trail.open>a,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail.open>span {
	padding-bottom: 10px;
	border-bottom: 1px solid;
}

ul.menu-corsi-sx a.active,
ul.menu-corsi-sx span.active {
	font-family: 'Abel-Regular', sans-serif;
}

ul.menu-corsi-sx a:hover,
ul.menu-corsi-sx a:focus,
ul.menu-corsi-sx span:hover {
	text-decoration: none;
}

ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:after,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:hover:after,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:after,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:hover:after {
	content: "\FE40";
	right: 30px;
	position: absolute;
	top: 20px;
}

ul.menu-corsi-sx>li>ul>li.expanded.active-trail.open>a:after,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail.open>a:hover:after,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail.open>span:after,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail.open>span:hover:after {
	content: "\FE3F";
	top: 4px;
}

ul.menu-corsi-sx>li>a:before,
ul.menu-corsi-sx>li>a:hover:before,
ul.menu-corsi-sx>li>span:before,
ul.menu-corsi-sx>li>span:hover:before {
	content: '<';
	left: 15px;
	position: absolute;
	color: #ffffff;
}

ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:before,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:hover:before,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:before,
ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:hover:before {
	left: 30px;
}

ul.menu-corsi-sx>li>ul>li>ul li a.active:before,
ul.menu-corsi-sx>li>ul>li>ul li a:hover:before,
ul.menu-corsi-sx>li>ul>li>ul li span.active:before,
ul.menu-corsi-sx>li>ul>li>ul li span:hover:before {
	left: 45px;
}

ul.menu-corsi-sx>li>ul ul li a,
ul.menu-corsi-sx>li>ul ul li span {
	font-size: 22px;
}

#quicktabs-eventi .quicktabs-tabs {
	position: static;
	padding-left: 20px;
}

.archivioeventi .button.absolute>a {
	left: 0;
}

.archivioeventi .view-filters,
.archivionews .view-filters {
	padding: 20px 30px;
	background: #f2f2f2;
	margin-top: 20px;
}

.archivioeventi .view-filters .views-exposed-form .views-exposed-widget,
.archivionews .view-filters .views-exposed-form .views-exposed-widget {
	max-width: 280px;
}

.views-exposed-form .views-exposed-widget .btn {
	margin-top: 1.85em;
}

.bannernews-www .views-row {
	border-bottom: 1px solid #e5e5e5;
	padding: 30px 0;
}

.bannernews-www .dataevento>span,
.bannernews-www .dataevento>div {
	display: inline;
	padding: 0 10px;
	background: #cfcfcf;
}

.bannernews-www.archivioeventi .views-row {
	position: relative;
	padding-bottom: 90px;
}

.bannernews-www.archivioeventi .dataevento {
	margin: 20px 0;
}

.view-ugov-view-degree .morecat {
	padding: 0px 30px;
	margin-bottom: 30px;
	margin-top: 10px;
}

.view-ugov-view-degree .morecat li {
	display: inline-block;
}

.view-ugov-view-degree .morecat a {
	color: #000000;
	display: block;
	padding: 10px 15px;
}

.view-ugov-view-degree .morecat a.active {
	color: #ffffff;
	background: #d61524;
}

.views-summary {
	padding: 10px 30px;
	background-color: #f2f2f2;
}

.views-summary li {
	display: inline-block;
}

.views-summary li>a {
	color: #000000;
	display: block;
}

.views-summary li>a:after {
	content: "|";
	display: inline-block;
	padding: 0 2px 0 8px;
}

.views-summary li:nth-last-child(1)>a:after {
	content: none;
	display: none;
}

.views-summary li>a.active {
	color: #d61524;
}

.bannernews-www .titolonews a,
.bannernews-www .titolonews a {
	display: block;
	font-size: 22px;
	line-height: 1;
	margin: 15px 0;
}

.bannernews-www .titolonews.big a,
.bannernews-www .titolonews.big a {
	font-size: 30px;
}

.archivionews .views-row {
	margin: 40px 0;
	padding: 0 30px;
}

.archivionews .data.gray {
	background: #cfcfcf none repeat scroll 0 0;
	display: inline;
	font-size: 15px;
	padding: 2px 10px;
}

.archivionews .sottotitolo {
	margin: 15px 0 40px;
}

.archivionews .views-row.bottomborder {
	border-bottom: 1px solid #000000;
}

.block-easy-social {
	position: absolute;
	right: 20px;
	display: inline;
	z-index: 3;
	top: -15px;
}

.block-easy-social .easy_social_box {
	position: relative;
	float: left;
}

.block-easy-social .easy_social_box.hide-easy-social-box,
.block-easy-social .easy_social_box.hide-easy-social-box iframe {
	visibility: hidden !important;
	cursor: pointer;
}

.share-icon {
	margin-left: 10px;
	position: relative;
	bottom: 3px;
	cursor: pointer;
}

.fb-like {
	width: 137px;
	margin-left: -78px;
	top: -2px;
}

.servicebox .views-row {
	border: 1px solid;
	margin-bottom: 20px;
	padding: 10px 10px 30px;
	text-align: center;
	position: relative;
}

.servicebox .service-title {
	margin-bottom: 25px;
	min-height: 42px;
}

.servicebox .service-img img {
	margin: 0 auto;
}

.servicebox.flexbox.column4 .flex-item:nth-child(4n+2),
.servicebox.flexbox.column4 .flex-item:nth-child(4n+3),
.servicebox.flexbox.column4 .flex-item:nth-child(4n+4) {
	margin-bottom: 20px;
}

.servicebox #info {
	bottom: 40px;
	font-size: 15px;
	left: 0;
	margin: 0 10%;
	position: absolute;
	width: 80%;
	color: #000000;
}

.data-bando {
	padding: 20px 0;
}

.feedback-button-right a {
	transform-origin: right bottom 0;
	letter-spacing: 2px;
}

.webform-feedback {
	transform: rotate(90deg);
}

.webform-feedback {
	background-color: yellow;
	color: #000;
	z-index: 10;
}

[id="webform-feedback-block"] {
	z-index: 10001;
	height: 75%;
	width: 100%;
}

[id="webform-feedback-block"] h2 {
	font-size: 25px;
	margin-bottom: 10px;
	margin-top: -25px;
}

[id="formclose"] {
	background-color: transparent;
	z-index: 10002;
	height: 1px;
	width: 25px;
}

[id="webform-feedback-block"],
[id="formclose"] {
	right: 0;
}

[id="formclose"] a {
	background-color: black;
	color: white !important;
	padding: 2px;
}

.webform-feedback-close {
	margin: 15px 50px 0 0;
}

[id="webform-feedback-block"] .table>tbody>tr>td {
	padding: 8px 0;
	line-height: 18px;
}

.webform-grid-option .radio input[type="radio"] {}

.webform-feedback-form .help-block {
	font-size: 16px;
}

@media all and (max-width:420px) {
	.webform-grid tr {
		padding: 5px;
	}
}

@media all and (min-width:400px) {
	[id="webform-feedback-block"] h2 {
		font-size: 25px;
		margin-bottom: 10px;
		margin-top: -25px;
	}
	[id="webform-feedback-block"] .webform-client-form .form-group {
		background: #f2f2f2 none repeat scroll 0 0;
		border-bottom: 1px solid #fff;
		margin-bottom: 0;
		margin-top: 0px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	[id="webform-feedback-block"] .table>tbody>tr>td {
		padding: 8px 0;
		line-height: 18px;
	}
	[id="webform-feedback-block"] .table>tbody>tr>td.checkbox {
		width: 5%;
	}
	[id="formclose"] {
		height: 1px;
	}
	.webform-feedback {
		padding: 13px 20px;
		border: 1px solid #fff;
		font-size: 20px;
	}
	.feedback-button-right a {
		right: 50px;
	}
	.webform-feedback-close {
		margin: 15px 50px 0 0;
	}
	.img_right .field-name-field-foto-paragrafo.field-type-image {
		float: right;
		margin: 0 0 0 10px;
	}
	.img_left .field-name-field-foto-paragrafo.field-type-image {
		float: left;
		margin: 0 10px 0 0;
	}
	.paragraphs-item .aprichiudi-opzione-parent .field-name-field-foto-paragrafo.field-type-image {
		margin: 0px;
	}
	.paragraphs-item-testo .field-name-field-foto-paragrafo img {
		margin: 0;
		max-width: 145px;
	}
	.hititle .titolo_box_2 {
		font-size: 38px;
		top: 114px;
	}
}

@media all and (min-width:600px) {
	[id="webform-feedback-block"] {
		width: 90%;
	}
	[id="webform-feedback-block"],
	[id="formclose"] {
		right: 60px;
	}
	.img_right .field-name-field-foto-paragrafo.field-type-image {
		margin: 0 0 0 30px;
	}
	.img_left .field-name-field-foto-paragrafo.field-type-image {
		margin: 0 30px 0 0;
	}
	.paragraphs-item .aprichiudi-opzione-parent .field-name-field-foto-paragrafo.field-type-image {
		margin: 0px;
	}
	.paragraphs-item-testo .field-name-field-foto-paragrafo img {
		max-width: unset;
	}
}

@media all and (max-width:520px) {
	.carousel-inner>.item>img,
	.carousel-inner>.item>a>img {
		height: 120px;
		width: auto;
		max-width: none;
	}
	.offertaview.aree .view-grouping-content .ui-state-default .area {
		padding-right: 60px;
	}
	.offertaview.aree .view-grouping-content .ui-state-default .areaextra {
		display: none;
	}
}

@media all and (max-width:767px) {
	.page-ugov-person #content-header.hidden-xs,
	.node-type-article #content-header.hidden-xs,
	.node-type-evento #content-header.hidden-xs,
	.node-type-avvisi #content-header.hidden-xs {
		display: block !important;
		margin-bottom: 20px;
		margin-top: 0px;
	}
	.row_flex {
		display: flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
	}
	.page-ugov-person #col1 {
		order: 1;
	}
	.page-ugov-person #col2 {
		order: 2;
	}
	.ugov-persona .titolo_pagina {
		display: none;
	}
	.mobilefull.button>a,
	.mobilefull button,
	.mobilefull.button .more-link a {
		width: 100%;
		max-width: 100%;
	}
	/*.logo img {
		width: 98px;
		height: auto;
	}*/
	#block-views-area-disciplinari-block .button,
	.field-name-corsi-per-area-disciplinare .button {
		width: auto;
	}
	#block-views-area-disciplinari-block .button>a,
	.field-name-corsi-per-area-disciplinare .button>a {
		background-position: right 14px;
		font-size: 17px;
		padding: 10px 30px 10px 20px;
		width: 150px;
		margin: 30px 0 0;
	}
	.vocabulary-aree-disciplinari.view-mode-full .row>div.col-sm-3 {
		margin-top: 30px;
	}
	table,
	thead,
	tbody,
	th,
	td,
	tr {
		display: block;
		max-width: 100%;
		border: none;
	}
	thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
	tr {
		background: #f2f2f2 none repeat scroll 0 0;
		border-width: 0 0 1px 0;
		border-color: #c3c3c3;
		padding: 10px 20px;
	}
	table tr:nth-child(2n) {
		background: #ffffff;
	}
	td {
		border: none;
		padding: 10px 0;
		position: relative;
		overflow-wrap: break-word;
	}
	.page-search td {
		border: none;
		padding: 0;
		position: relative;
		overflow-wrap: break-word;
	}
	.page-search tr {
		background: none;
		padding: 0px;
	}
	table tr:nth-child(2n) {
		background: none;
	}
	.table-responsive>.table>thead>tr>th,
	.table-responsive>.table>tbody>tr>th,
	.table-responsive>.table>tfoot>tr>th,
	.table-responsive>.table>thead>tr>td,
	.table-responsive>.table>tbody>tr>td,
	.table-responsive>.table>tfoot>tr>td {
		white-space: initial;
	}
	.hp-slider-subtitle {
		display: none;
	}
	.row_top {
		height: auto !important;
	}
	.button {
		width: 250px;
	}
	.button.absolute {
		margin: 0 auto;
	}
	.paragraphs-item-homebox .button.absolute>a,
	.group-right .paragraphs-item-homebox .button>a {
		margin: 30px 0 20px;
		position: static;
	}
	.paragraphs-item-homebox.bgimg .button.absolute>a {
		margin: 0;
		position: absolute;
	}
	.menu-profili {
		margin-bottom: 30px;
	}
	.field-collection-item-field-banner img {
		width: 150px;
		height: auto;
	}
	.boxscheda {
		border-bottom: 1px solid;
		margin: 0 0 30px;
		padding: 0 0 30px;
		width: 100%;
	}
	.boxscheda.last {
		margin-bottom: 0;
	}
	.boxscheda img {
		position: relative;
		top: 4px;
	}
	.boxscheda .field-name-field-testo-paragrafo {
		min-height: 70px;
	}
	.boxscheda .button.absolute {
		margin: 0;
	}
	.boxscheda .button.absolute>a {
		position: static;
		margin: 30px 0 0;
	}
	#block-easy-social-easy-social-block-1 {
		display: none;
	}
	.field-name-opinioni-degli-studenti .button>a {
		width: 280px;
		font-size: 21px;
		padding: 19px 30px 19px 15px;
		background-position: right 23px;
	}
}

@media all and (min-width:768px) {
	.region-header {
		margin-top: 35px;
	}
	.not-front .row_gray {
		margin-top: 60px;
	}
	.button>a,
	.button button,
	.button .more-link a {
		width: 142px;
		padding: 9px 35px 9px 15px;
		background-position: right 12px;
		font-size: 16px;
		margin-left: 0;
		margin-right: 0;
	}
	.field-name-opinioni-degli-studenti .button>a {
		width: 260px;
	}
	.small.button>a,
	.small button,
	.small.button .more-link a {
		padding: 9px 35px 9px 15px;
		background-position: right 13px;
		font-size: 16px;
	}
	.button.absolute>a,
	.absolute button,
	.button.absolute .more-link a {
		left: 20px;
	}
	.flexbox>div {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.flexbox .flex-item {
		width: 48%;
	}
	.flexbox .flex-item:nth-child(odd) {
		margin-right: 4%;
	}
	.flexbox .flex-item img {
		width: 100%;
	}
	.flexbox.smallimg .flex-item img {
		width: auto;
	}
	.filtro-submit {
		bottom: 20px;
		position: absolute;
		right: 20px;
	}
	#block-search-form {
		position: absolute;
		right: 0;
		width: 50%;
		top: 120px;
	}
	.navbar-collapse {
		top: 190px;
	}
	.carousel-inner>.item>img,
	.carousel-inner>.item>a>img {
		height: 285px;
		width: auto;
		max-width: none;
	}
	.hp-slider-overlay {
		padding: 25px 20px;
	}
	.hp-slider-title {
		font-family: "Abel-Regular", sans-serif;
		font-size: 46px;
		line-height: 46px;
	}
	.hp-slider-subtitle {
		font-size: 32px;
		line-height: 32px;
	}
	.media-tablet-desktop .hp-slider-subtitle,
	.media-tablet-landscape .hp-slider-subtitle,
	.media-phone .hp-slider-subtitle {
		float: left;
	}
	body .row_top {
		background: #ebebeb;
		margin-top: 1px;
	}
	#block-menu-block-16 h2.block-title {
		font-size: 60px;
		margin-top: 65px;
		margin-bottom: 40px;
		margin-left: 10px;
	}
	.menu-block-16 ul li {
		width: 50%;
		margin-bottom: 30px;
		float: left;
	}
	.menu-block-16 ul li a {
		font-size: 35px;
		line-height: 33px;
	}
	.node-elementi-della-home-page.view-mode-full {
		margin: 60px -20px 40px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.node-elementi-della-home-page.view-mode-full>div {
		width: 49.93%;
	}
	.node-elementi-della-home-page.view-mode-full>div.group-right {
		width: 100%;
	}
	.node-elementi-della-home-page.view-mode-full .paragraphs-items-field-colonna-3 {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
	.node-elementi-della-home-page.view-mode-full .paragraphs-items-field-colonna-3>div {
		width: 33.3333%;
	}
	.paragraphs-item-homebox {
		height: 340px;
		padding-bottom: 0;
	}
	.paragraphs-item-homebox.smallbox {
		height: 220px;
	}
	.group-right .paragraphs-item-homebox.smallbox {
		height: 340px;
	}
	.paragraphs-item-homebox .file-video {
		padding: 99px 0 0;
	}
	.paragraphs-item-homebox .homebox-content {
		max-height: 260px;
	}
	.paragraphs-item-homebox.smallbox .homebox-content {
		max-height: 144px;
	}
	.group-right .paragraphs-item-homebox.smallbox .homebox-content {
		max-height: 260px;
	}
	.paragraphs-item-homebox.bgimg .homebox-content {
		height: 340px;
	}
	.paragraphs-item-homebox .homebox-content:before {
		content: '...';
		position: absolute;
		right: 4px;
		bottom: 0;
		letter-spacing: 2px;
		font-family: 'Abel-Regular', sans-serif;
	}
	.paragraphs-item-homebox .homebox-content:after {
		bottom: 0;
		content: "";
		display: block;
		height: 20px;
		margin-top: -10px;
		position: relative;
		right: -99%;
		width: 20px;
		background: #ffffff;
	}
	.paragraphs-item-homebox.bgimg .homebox-content:before,
	.paragraphs-item-homebox.bgimg .homebox-content:after {
		content: "";
		display: none;
	}
	.quicktabs-tabs {
		top: -54px;
		left: 20px;
	}
	#block-quicktabs-blocco-home ul.quicktabs-tabs li {
		margin-right: 10px;
	}
	#block-quicktabs-blocco-home ul.quicktabs-tabs li a {
		font-size: 35px;
		padding: 5px 15px;
	}
	.bannernews .col-xs-12 {
		padding: 40px 40px 0;
		position: relative;
	}
	.bannernews.news .col-xs-12 {
		padding: 30px 40px 0;
	}
	.bannernews.news.grigio .col-xs-12 {
		padding: 30px 20px 0;
	}
	.bannernews .titolo,
	.bannernews .sottotitolo {
		padding-left: 130px;
	}
	.bannernews .titolo a,
	.bannernews .titolonews a,
	.archivionews .titolonews a {
		font-size: 25px;
		line-height: 30px;
	}
	.bannernews .sottotitolo {
		font-size: 25px;
		line-height: 30px;
	}
	.bannernews .more-link a {
		margin: 30px 40px;
	}
	.bannernews.grigio .more-link a {
		margin: 30px 20px;
	}
	#block-jquery-social-stream-jquery-social-stream {
		margin-top: 65px;
	}
	#block-jquery-social-stream-jquery-social-stream h2 {
		font-size: 54px;
		line-height: 40px;
		margin: 60px 0 40px;
	}
	#block-jquery-social-stream-jquery-social-stream .social_title {
		display: inline;
	}
	#block-jquery-social-stream-left-wrapper {
		width: 335px;
	}
	#block-jquery-social-stream-left-wrapper ul.social-stream-jselector li {
		border-bottom: 1px solid #e3e3e3;
		border-top: 1px solid #e3e3e3;
	}
	#block-jquery-social-stream-wrapper {
		padding-left: 350px;
	}
	.dcsns-content {
		margin-bottom: 55px;
	}
	.follow-us-link {
		top: 80px;
		right: 20px;
	}
	.footer #block-menu-block-26 .nav>li,
	.footer #block-menu-block-21 .nav>li {
		display: inline-block;
	}
	.footer #block-menu-block-26 .nav>li>a,
	.footer #block-menu-block-26 .nav>li span.nolink,
	.footer #block-menu-block-21 .nav>li>a,
	.footer #block-menu-block-21 .nav>li span.nolink {}
	p.footer_text {
		padding-left: 62px;
		background: url(/sites/all/themes/unimib/img/logo_min.png) no-repeat 0 3px;
		background-size: 45px auto;
		line-height: 18px;
		min-height: 65px;
		padding-top:20px;
		margin: 0;
	}
	.menu-block-wrapper.menu-block-14 {
		padding-left: 65px;
	}
	.footer #block-menu-block-14 .nav>li>a,
	.footer #block-menu-block-14 .nav>li span.nolink {
		padding: 10px 0;
	}
	.footer .region-footer .nav>li>a {
		padding: 10px 4px;
	}
	.footer .region_footer_bottom .nav>li.first>a {
		padding-left: 0;
	}
	#block-views-notizie-eventi-block-5 .block-title {
		padding-left: 20px;
	}
	.fascia_didascalia {
		background-color: rgba(167, 30, 59, 0.72);
		position: absolute;
		bottom: 0px;
	}
	.paragraphs-item-immagine-link .paragraphs-items-field-link-una-colonna {
		float: left;
		width: 50%;
	}
	.paragraphs-item-immagine-link .paragraphs-items-field-link-due-colonne {
		float: right;
		width: 50%;
	}
	.paragraphs-item-immagine-link .paragraphs-items-field-link-una-colonna.mono {
		width: 100%;
	}
	.paragraphs-item-immagine-link .paragraphs-items-field-link-una-colonna.mono li {
		margin-bottom: 30px;
	}
	.paragraphs-item-immagine-link .paragraphs-items-field-link-una-colonna.mono li a {
		text-decoration: underline;
	}
	.paragraphs-item-immagine-link .button:before {
		clear: both;
		content: "";
		display: table;
	}
	.paragraphs-item-link-correlati li {
		padding-left: 30px;
	}
	.paragraphs-item-scheda {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.boxscheda {
		padding: 20px 20px 94px;
		border: 1px solid #b7b7b7;
	}
	.titolo_scheda {
		min-height: 85px;
	}
	.boxscheda img {
		max-width: 100%;
	}
	.scheda>.field {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
	.scheda>.field-label-inline .field-label {
		width: 30%;
		margin-bottom: 0;
	}
	.view-mode-corsi_di_studio>.row>.col-md-4 {
		padding-right: 50px;
	}
	#legenda {
		margin-top: 30px;
		border: 2px solid #dadada;
		padding: 30px;
	}
	#ugov-of-cds-ugov-accordion.ui-accordion .ui-accordion-content>.field>.field-label {
		padding: 20px 30px;
		width: 25%;
	}
	#ugov-of-cds-ugov-accordion.ui-accordion .ui-accordion-content>.field>.field-items {
		padding: 20px 25px;
	}
	.ugov-persona-ugov>.row>div {
		float: right;
	}
	.webform-grid-option-input-img,
	.webform-grid-option-input-txt {
		display: none;
	}
	.paragraphs-items-field-riga-4 .paragraphs-item-homebox,
	.paragraphs-items-field-riga-1 .paragraphs-item-homebox {
		width: 50%;
		float: left;
	}
	.paragraphs-items-field-riga-2 .img-responsive,
	.paragraphs-items-field-riga-3 .img-responsive {
		height: 400px;
		width: 75%;
		float: left;
	}
	.paragraphs-items-field-riga-2 .paragraphs-item-homebox,
	.paragraphs-items-field-riga-3 .paragraphs-item-homebox {
		width: 25%;
		float: left;
		height: 400px;
	}
}

@media all and (max-width:1199px) {
	.container {
		width: auto;
	}
	.mobilefull .container {
		padding-left: 0;
		padding-right: 0;
	}
	.navbar-header {
		float: none;
	}
	.navbar.container {
		max-width: 100%;
	}
	.navbar-header,
	.navbar-collapse {
		margin: 0;
	}
	.navbar-collapse {
		background-color: #ffffff;
		z-index: 100;
		width: 100%;
		position: absolute;
		margin: 0 -20px;
	}
	.navbar-default .navbar-collapse,
	.navbar-default .navbar-form {
		border-color: #fff;
	}
	.navbar-collapse .nav>li>a,
	.navbar-collapse .nav>li>span {
		padding: 12px 55px 12px 15px;
		font-family: 'Abel-Regular';
		font-size: 21px;
		text-align: right;
		border-top: 1px solid #d61524;
	}
	.navbar-collapse #block-menu-block-34 .nav>li>a,
	.navbar-collapse #block-menu-block-1 .nav>li>a {
		border-color: #ffffff;
		color: #ffffff;
		background: #d61524;
	}
	.navbar-collapse .int-24 .nav>li>a,
	.navbar-collapse #block-menu-block-28 .nav>li>a,
	.navbar-collapse #block-menu-block-10 .nav>li>a {
		color: #000000;
		font-family: Mako,Helvetica,sans-serif,Arial;
		text-transform: none;
		border: none;
	}
	.int-22 .nav>li>a {
		font: 20px/22px 'Abel-Regular', sans-serif;
		color: #ffffff;
		padding: 10px;
	}
	._down-empty-arrow:after,
	._up-empty-arrow:after,
	.navbar-collapse.in .dropdown:after {
		color: #d61524;
		font-weight: bold;
		font-size: 1.2em;
		position: absolute;
		right: 20px;
		z-index: -1;
		top: 20px;
		cursor: pointer;
	}
	._down-empty-arrow:after,
	.navbar-collapse.in .dropdown:after {
		content: "\FE40";
	}
	._up-empty-arrow:after,
	.navbar-collapse.in .dropdown.open:after {
		content: "\FE3F";
		top: 8px;
	}
	#navbar .dropdown-menu {
		background: inherit;
	}
	.dropdown-menu {
		background-clip: padding-box;
		border: none;
		border-radius: 0;
		box-shadow: none;
		margin: 0;
		width: 100%;
		padding: 0;
/* 		position: relative; */
		top: unset;
		float: none;
	}
	.dropdown-menu>li>a:hover,
	.dropdown-menu>li>a:focus {
		background-color: transparent;
		border-color: #333333;
		color: #333333;
		text-decoration: underline;
	}
	.dropdown-menu>.active>a,
	.dropdown-menu>.active>a:hover,
	.dropdown-menu>.active>a:focus {
		background-color: transparent;
		border-color: #333333;
		color: #333333;
		text-decoration: underline;
	}
	#block-menu-block-17 .dropdown-menu>li>a {
		font-family: Mako,Helvetica,sans-serif,Arial;
		font-size: 18px;
		color: #d61524;
		padding: 5px 0;
		margin: 0 55px;
		text-align: right;
		white-space: normal;
	}
	#block-menu-block-17 .dropdown-menu>li>span.nolink {
		font-size: 17px;
		color: #333333;
		text-transform: uppercase;
		display: block;
		text-align: right;
		margin: 0 55px 10px;
		font-family: 'Abel-Regular', sans-serif;
	}
	.menu-block-17 ul.nav {
		display: flex;
		flex-direction: column-reverse;
	}
	.menu-block-17 li {
		flex: 0 0 auto;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
	}
	.navbar-default .navbar-collapse {
		padding-bottom: 10px;
		border-bottom: 1px solid #d61524;
	}
	.navbar-toggle {
		background-color: transparent;
		background-image: none;
		border: none;
		float: right;
		margin-bottom: 8px;
		margin-right: 0px;
		margin-top: 8px;
		padding: 2px 0px;
		position: relative;
		display: block;
	}
	.navbar-default .navbar-toggle:hover,
	.navbar-default .navbar-toggle:focus {
		background-color: transparent;
	}
	.navbar-default .navbar-toggle .icon-bar {
		background-color: #72131b;
	}
	.navbar-toggle .icon-bar {
		border-radius: 0;
		border-top: 3px solid #ffffff;
		display: block;
		height: 8px;
		width: 38px;
	}
	#navbar div.navbar-header #search-trigger,
	#search-trigger-mobile {
		font: 33px/32px 'Abel-Regular', sans-serif;
		border-right: 1px solid black;
		padding-right: 10px;
		position: absolute;
		right: 50px;
		top: 10px;
		cursor: pointer;
	}
	#search-trigger-mobile {
		font: 25px/32px 'Abel-Regular', sans-serif;
		padding-right: 18px;
		right: 75px;
		z-index: 10000;
	}
	.navbar-nav {
		float: none!important;
		margin: 7.5px -15px;
	}
	.navbar-nav>li {
		float: none;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.carousel-control {
		display: none;
	}
	.bannernews .views-row {
		float: none;
		min-height: 100px;
	}
	.navbar-collapse .menu-inline ul>li {
		float: none;
	}
	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-collapse.collapse.in {
		display: block!important;
	}
}

@media all and (min-width:992px) {
	[id="webform-feedback-block"] {
		width: 680px;
	}
	[id="webform-feedback-block"],
	[id="formclose"] {
		right: 200px;
	}
	[id="webform-feedback-block"] h2 {
		font-size: 25px;
		margin-bottom: 10px;
		margin-top: -25px;
	}
	[id="webform-feedback-block"] .webform-client-form .form-group {
		background: #f2f2f2 none repeat scroll 0 0;
		border-bottom: 1px solid #fff;
		margin-bottom: 0;
		margin-top: 0px;
		padding-top: 5px;
		padding-bottom: 5px;
	}
	[id="webform-feedback-block"] .table>tbody>tr>td {
		padding: 8px;
		line-height: 18px;
	}
	[id="webform-feedback-block"] .table>tbody>tr>td.checkbox {
		width: 5%;
	}
	[id="formclose"] {
		height: 1px;
	}
	.webform-feedback {
		padding: 13px 20px;
		border: 1px solid #fff;
		font-size: 20px;
	}
	.feedback-button-right a {
		right: 50px;
	}
	.webform-feedback-close {
		margin: 15px 50px 0 0;
	}
	.bottom_space {
		margin-bottom: 40px;
	}
	.menu-inline ul>li {
		float: left;
	}
	.container {
		width: auto;
	}
	.navbar-collapse {
		background-color: rgba(255, 255, 255, 0.95);
		top: 60px;
		right: 0;
		margin: 0;
	}
	.navbar-default .navbar-collapse {
		padding-bottom: 0;
		border-bottom: none;
		width: 350px;
	}
	#block-menu-block-17 .nav>li {
		border-bottom: 1px solid #d61524;
	}
	.navbar-collapse .nav>li>a {
		border-top: none;
	}
	#navbar .nav .open>a,
	#navbar .nav .open>a:hover,
	#navbar .nav .open>a:focus {
		background: inherit;
	}
	#block-menu-block-11 li {
		border-right: 1px solid #ffffff;
		width: 20%;
	}
	#block-menu-block-11 li.last {
		border-right: none;
	}
	#block-menu-block-11 .nav>li>a {
		font: 20px/22px 'Abel-Regular', sans-serif;
		color: #ffffff;
		padding: 20px;
	}
	body.not-front .background_image {
		display: none;
	}
	body.node-type-unimib-international .background_image {
		display: block;
	}
	.int-22 li {
		border-right: 1px solid #ffffff;
		height: 80px;
		width: 16%;
	}
	.int-22 li.menu-mlid-8444 {
		width: 13%;
	}
	.int-22 li.menu-mlid-8445 {
		width: 22%;
	}
	.int-22 li.last {
		border-right: none;
	}
	.int-22 .nav>li>a {
		font: 20px/22px 'Abel-Regular', sans-serif;
		color: #ffffff;
		padding: 15px;
	}
	.int-22 .nav>li.last>a {
		padding-right: 5px;
	}
	ul.menu-corsi-sx {
		border: none;
		margin: 0;
	}
	ul.menu-corsi-sx>li>a,
	ul.menu-corsi-sx>li>span {
		padding: 20px 30px;
		font-size: 18px;
	}
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span {
		padding: 16px 30px 0 40px;
		font-size: 18px;
		border: none;
	}
	ul.menu-corsi-sx>li>ul>li>a,
	ul.menu-corsi-sx>li>ul>li>span {
		padding: 15px 30px 0 40px;
	}
	ul.menu-corsi-sx>li>ul>li>ul li a,
	ul.menu-corsi-sx>li>ul>li>ul li span {
		padding: 15px 30px 0 50px;
	}
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:after,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:hover:after,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:after,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:hover:after {
		content: none;
		display: none;
	}
	ul.menu-corsi-sx>li>ul>li a.active:before,
	ul.menu-corsi-sx>li>ul>li a:hover:before,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:before,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>a:hover:before,
	ul.menu-corsi-sx>li>ul>li span.active:before,
	ul.menu-corsi-sx>li>ul>li span:hover:before,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:before,
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail>span:hover:before {
		content: '>';
		left: 28px;
		position: absolute;
	}
	ul.menu-corsi-sx>li>ul>li>ul li a.active:before,
	ul.menu-corsi-sx>li>ul>li>ul li a:hover:before,
	ul.menu-corsi-sx>li>ul>li>ul li span.active:before,
	ul.menu-corsi-sx>li>ul>li>ul li span:hover:before {
		left: 40px;
	}
	ul.menu-corsi-sx>li>ul ul li a,
	ul.menu-corsi-sx>li>ul ul li span {
		color: #000000;
		font-size: 18px;
	}
	.hp-slider-title {
		font-size: 35px;
		line-height: 35px;
	}
	.hp-slider-subtitle {
		font-size: 25px;
	}
	.menu-block-16 ul li {
		width: 14.2%;
	}
	.menu-block-16 ul li a {
		font: 23px/25px Mako,Helvetica,sans-serif,Arial;
		height: 80px;
	}
	#overlay {
		top: -105px;
	}
	.node-elementi-della-home-page.view-mode-full>div {
		width: 33.3%;
		border-right: 1px solid #ffffff;
	}
	.node-elementi-della-home-page.view-mode-full>div.group-right {
		width: 33.3%;
	}
	.node-elementi-della-home-page.view-mode-full .paragraphs-items-field-colonna-3 {
		display: block;
	}
	.node-elementi-della-home-page.view-mode-full .paragraphs-items-field-colonna-3>div {
		width: auto;
	}
	.group-right .paragraphs-item-homebox.smallbox {
		height: 220px;
	}
	.group-right .paragraphs-item-homebox.smallbox .homebox-content {
		max-height: 144px;
	}
	.hititle .titolo_box_2 {
		font-size: 38px;
		top: 114px;
	}
	.quicktabs-tabs {
		left: 0px;
		top: -52px;
	}
	#block-quicktabs-blocco-home ul.quicktabs-tabs li a {
		font-size: 30px;
		padding: 7px 15px;
	}
	.bannernews .col-xs-12 {
		padding: 50px 30px 0 20px;
		min-height: 275px;
	}
	.bannernews.news .col-xs-12 {
		margin-right: 15px;
		border-right: 1px solid #ffffff;
		padding: 50px 20px 0;
	}
	.bannernews.news.grigio .col-xs-12 {
		padding: 50px 20px 0;
	}
	.bannernews.news .col-xs-12.views-row-last {
		border: none;
		margin-right: 0;
	}
	.bannernews .view-content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
	.bannernews .titolo,
	.bannernews .sottotitolo {
		padding-left: 115px;
	}
	.bannernews .titolo a {
		font-size: 25px;
		line-height: 25px;
	}
	.bannernews .sottotitolo {
		font-size: 18px;
		line-height: 18px;
	}
	.bannernews .more-link a,
	.bannernews.grigio .more-link a {
		margin: 0;
		position: absolute;
		right: 0;
		bottom: 20px;
	}
	#block-menu-menu-social {
		margin-top: 100px;
	}
	#block-menu-menu-social .menu {
		float: right;
	}
	#block-menu-menu-social .menu li {
		display: inline-block;
		margin: 0 1px 10px 0;
	}
	#block-menu-menu-social .menu li a,
	#block-menu-menu-social .menu li span {
		background-size: 200px auto;
		background-color: #d61524;
		background-image: url(/sites/all/themes/unimib/img/social.svg);
		background-repeat: no-repeat;
		display: block;
		height: 28px;
		position: relative;
		text-indent: -9999px;
		width: 28px;
		padding: 0;
	}
	#block-menu-menu-social .menu li a[title="facebook"],
	#block-menu-menu-social .menu li span[title="facebook"] {
		background-position: -66px -39px;
	}
	#block-menu-menu-social .menu li a[title="twitter"],
	#block-menu-menu-social .menu li span[title="twitter"] {
		background-position: -100px -40px;
	}
	#block-menu-menu-social .menu li a[title="youtube"],
	#block-menu-menu-social .menu li span[title="youtube"] {
		background-position: -85px -123px;
	}
	#block-menu-menu-social .menu li a[title="linkedin"],
	#block-menu-menu-social .menu li span[title="linkedin"] {
		background-position: -94px -82px;
	}
	#block-menu-menu-social .menu li a[title="gplus"],
	#block-menu-menu-social .menu li span[title="gplus"] {
		background-position: -68px -82px;
	}
	#block-menu-menu-social .menu li a[title="Instagram"],
	#block-menu-menu-social .menu li span[title="Instagram"] {
		background-position: -122px -83px;
	}
	#block-menu-menu-social .menu li a[title="Snapchat"],
	#block-menu-menu-social .menu li span[title="Snapchat"] {
		background-position: -153px -40px;
	}
	#block-jquery-social-stream-jquery-social-stream .stream li {
		width: 50%;
		border-right: 1px solid #f2f2f2;
	}
	.dcsns .controls a.next {
		right: 1px;
	}
	.int-25 ul,
	#block-menu-block-15 ul {
		float: right;
	}
	.int-25 .nav>li>a,
	#block-menu-block-15 a {
		padding: 15px 3px;
	}
	#block-block-1 {
		margin-top: 75px;
	}
	.footer_text {
		font-size: 13px;
		line-height: 17px;
		background-position: 0 0;
	}
	.menu-block-wrapper.menu-block-14 {
		margin-top: 10px;
	}
	.footer #block-menu-block-14 .nav>li>a,
	.footer #block-menu-block-14 .nav>li span.nolink {
		font-size: 13px;
	}
	.footer #block-menu-block-14 .nav>li>a:after,
	.footer #block-menu-block-14 .nav>li>span:after {
		padding: 0 3px;
	}
	.home-area .menu-corsi-sx>li>a {
		display: none;
	}
	.home-area .menu-corsi-sx {
		background: #d61524;
		padding-top: 5px;
	}
	.home-area .menu-corsi-sx .menu-level-1>li {
		margin: 0 0 10px;
	}
	.home-area .menu-corsi-sx .menu-level-1>li.last {
		margin-bottom: 5px;
	}
	.home-area .menu-corsi-sx .menu-level-1>li>a,
	.home-area .menu-corsi-sx .menu-level-1>li>span {
		color: #ffffff;
		padding: 10px 10px 0 25px;
	}
	.home-area .menu-corsi-sx .menu-level-1>li>a:hover:before,
	.home-area .menu-corsi-sx .menu-level-1>li>span:hover:before {
		content: none;
		display: none;
	}
	.home-area .menu-corsi-sx .menu-level-1>li>a:hover {
		font-family: "Abel-Regular";
	}
	#block-views-notizie-eventi-block-5 .block-title {
		padding-left: 10px;
	}
	#block-menu-block-12 {
		margin-bottom: 30px;
	}
	.titolo-evidenziato .titolo_paragrafo {
		padding: 12px 20px;
	}
	.paragraphs-item-testo.paragrafo-citazione .field-name-field-testo-paragrafo {
		font-size: 25px;
	}
	.label-bando {
		border-bottom: 3px solid #d61524;
		padding: 0 0 2px;
		margin-bottom: 5px;
	}
}

@media all and (max-width:991px) {
	h2.titolo_pagina {
		font-size: 36px;
	}
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail ul {
		display: none;
	}
	ul.menu-corsi-sx>li>ul>li.expanded.active-trail.open ul {
		display: block;
	}
	.bannernews .views-row:after {
		content: " ";
		display: block;
		padding-top: 30px;
		border-bottom: 1px solid #D38E9D;
	}
	.bannernews.news .views-row:after {
		border-color: #ffffff;
	}
	#overlay {
		display: none !important;
	}
	.paragraphs-item.last .entity-paragraphs-item {
		margin-bottom: 0;
	}
	.group-right .paragraphs-item-homebox {
		padding-bottom: 30px;
	}
	.group-right .paragraphs-item-homebox.bgimg {
		padding-bottom: 0;
	}
	.group-right .paragraphs-item-homebox .button>a {}
	.group-right .paragraphs-item-homebox .homebox-content::before,
	.group-right .paragraphs-item-homebox .homebox-content::after {
		display: none;
		content: "";
	}
}

@media all and (min-width:1200px) {
	
	.navbar-default .navbar-collapse {
		width: 100%;
		top: 0;
	}
	.navbar-header {
		position: absolute;
		z-index: 100;
		/*left: 70px;*/
	}
	.button>a,
	.button button,
	.button .more-link a {
		padding: 11px 35px 11px 15px;
		background-position: right 15px;
		font-size: 17px;
	}
	.small.button>a,
	.small button,
	.small.button .more-link a {
		padding: 11px 35px 11px 15px;
		background-position: right 15px;
		font-size: 17px;
	}
	.flexbox.column3 .flex-item {
		width: 30%;
	}
	.flexbox.column4 .flex-item {
		width: 23%;
	}
	.flexbox .flex-item:nth-child(odd) {
		margin-right: 0;
	}
	.flexbox.column3 .flex-item:nth-child(3n+2) {
		margin: 0 5%;
	}
	.flexbox.column4 .flex-item:nth-child(4n+2) {
		margin: 0 1% 0 2%;
	}
	.flexbox.column4 .flex-item:nth-child(4n+3) {
		margin: 0 1% 0 1%;
	}
	.flexbox.column4 .flex-item:nth-child(4n+4) {
		margin: 0 2% 0 1%;
	}
	.site-title-int {
		margin-top: 40px;
		padding-left: 790px;
		padding-right: 0px;
		padding-top: 30px;
	}
	.int-22 .nav>li>a {
		padding: 20px;
	}
	.paragraphs-items-field-riga-4 .paragraphs-item-homebox,
	.paragraphs-items-field-riga-1 .paragraphs-item-homebox {
		width: 50%;
		float: left;
	}
	.paragraphs-items-field-riga-2 .img-responsive,
	.paragraphs-items-field-riga-3 .img-responsive {
		width: 100%;
		height: 426px;
	}
	.paragraphs-items-field-riga-2 .paragraphs-item-homebox,
	.paragraphs-items-field-riga-3 .paragraphs-item-homebox {
		width: 25%;
		float: left;
		height: 426px;
	}
	#block-search-form .google-cse.form-search {
		position: absolute;
		right: 999999999px;
	}
	.int-24,
	#block-menu-block-10 {
		background: #cfcfcf;
		padding: 30px 20px;
		float: right;
	}
	#block-menu-block-28 {
		float: right;
	}
	.int-24,
	#block-menu-block-10 ul {
		list-style: none;
	}
	#block-menu-block-28 ul {
		background: #cfcfcf;
		list-style: none;
	}
	#block-menu-block-28 ul li {
		padding: 30px 0;
	}
	.int-24 .form-group,
	#block-menu-block-28 .form-group,
	#block-menu-block-10 .form-group {
		margin: 0;
	}
	.int-24 form,
	#block-menu-block-28 form,
	#block-menu-block-10 form {
		max-width: 160px;
	}
	.int-24 .form-control,
	#block-menu-block-28 .form-control,
	#block-menu-block-10 .form-control {
		height: 24px;
		padding: 2px 10px !important;
	}
	.int-24 .btn,
	#block-menu-block-28 .btn,
	#block-menu-block-10 .btn {
		padding: 3px 6px 2px;
		font-size: 12px;
		color: #ffffff;
	}
	.int-24 li.last,
	#block-menu-block-10 li.last {
		visibility: visible;
	}
	#block-menu-block-28 li:nth-child(8) {
		visibility: visible;
	}
	#block-menu-block-28 li:nth-child(9) {
		display: block;
	}
	.menu-block-24 .nav>li>a,
	.menu-block-28 .nav>li>a,
	.menu-block-10 .nav>li>a {
		color: #000000;
		text-transform: lowercase;
		padding: 0 16px;
		display: inline-block;
	}
	.int-24 .nav>li:after,
	#block-menu-block-28 .nav>li:after,
	#block-menu-block-10 .nav>li:after {
		content: "|";
		display: inline-block;
		position: relative;
		top: -1px;
	}
	.int-24 .nav>li.last,
	#block-menu-block-10 .nav>li.last {
		height: 22px;
		overflow-y: hidden;
	}
	#block-menu-block-28 .nav>li:nth-child(8) {
		height: 22px;
		overflow-y: hidden;
		padding-right: 15px;
	}
	.int-24 .nav>li.last:after,
	#block-menu-block-10 .nav>li.last:after {
		content: "";
	}
	#block-menu-block-28 .nav>li.last:after,
	#block-menu-block-28 .nav>li:nth-child(8):after {
		content: "";
	}
	#block-menu-block-28 .nav>li:nth-child(9) {
		background: #d61524;
		border-left: 2px solid white;
	}
	#block-menu-block-28 .nav>li:nth-child(9) a {
		color: white;
	}
	#search-trigger {
		text-transform: uppercase;
		padding: 0 5px 0 16px;
		cursor: pointer;
		font-family: 'Abel-Regular', sans-serif;
		line-height: 22px;
	}
	#search-trigger:after {
		display: inline-block;
		color: #ffffff;
		font-family: "Glyphicons Halflings";
		font-size: 11px;
		height: 20px;
		left: 5px;
		padding: 0 6px;
		position: relative;
		top: -1px;
		content: "\e003";
		background: #000000;
	}
	.menu-block-28 li a[title="inglese"],
	.menu-block-28 li span[title="inglese"],
	.menu-block-10 li a[title="inglese"],
	.menu-block-10 li span[title="inglese"] {
		background: url(/sites/all/themes/unimib/img/gbflag.png);
		background-repeat: no-repeat;
		background-position: center center;
		width: 27px;
		text-indent: -9999px;
		margin: 0 16px;
		padding: 0;
	}
	.menu-block-24 li a[title="italiano"],
	.menu-block-24 li span[title="italiano"],
	.menu-block-28 li a[title="italiano"],
	.menu-block-28 li span[title="italiano"],
	.menu-block-10 li a[title="italiano"],
	.menu-block-10 li span[title="italiano"] {
		background: url(/sites/all/themes/unimib/img/it.png);
		background-repeat: no-repeat;
		background-position: center center;
		width: 27px;
		text-indent: -9999px;
		margin: 0 16px;
		padding: 0;
	}
	#block-menu-block-3 {
		position: relative;
		top: 40px;
		clear: both;
	}
	#block-menu-block-3 .nav {
		position: relative;
		width: 100%;
		left: 20px;
	}
	#block-menu-block-3 .nav>li>a,
	#block-menu-block-3 .nav>li>span {
		font: 26px/26px 'Abel-Regular', sans-serif;
		padding: 10px 20px;
		color: #d61524;
		border-bottom: 10px solid #ffffff;
	}
	#block-menu-block-3 .nav>li {
		display: table-cell;
		float: right;
	}
	#block-menu-block-3 .nav>li.open {
		position: static;
	}
	#block-menu-block-3 .nav>li>a:after,
	#block-menu-block-3 .nav>li>span:after {
		background: #d61524;
		content: " ";
		display: inline-block;
		height: 16px;
		left: 0;
		position: absolute;
		top: 16px;
		width: 1px;
	}
	#block-menu-block-3 .nav>li.open>a:after,
	#block-menu-block-3 .nav>li.last>a:after,
	#block-menu-block-3 .nav>li.open>span:after,
	#block-menu-block-3 .nav>li.last>span:after {
		display: none
	}
	#block-menu-block-3 .nav>li.active-trail>a,
	#block-menu-block-3 .nav>li.open>a,
	#block-menu-block-3 .nav>li.open>a:hover,
	#block-menu-block-3 .nav>li.open>a:focus,
	#block-menu-block-3 .nav>li.active-trail>span,
	#block-menu-block-3 .nav>li.open>span,
	#block-menu-block-3 .nav>li.open>span:hover,
	#block-menu-block-3 .nav>li.open>span:focus {
		background: #d61524;
		color: #ffffff;
		margin-right: -1px;
	}
	#block-menu-block-3 .nav>li.active-trail>a.noactive,
	#block-menu-block-3 .nav>li.active-trail>span.noactive {
		background: #ffffff;
		color: #d61524;
		margin-right: 0;
	}
	#block-menu-block-3 .nav li.open+li>a,
	#block-menu-block-3 .nav li.open+li+li>a,
	#block-menu-block-3 .nav li.open+li+li+li>a,
	#block-menu-block-3 .nav li.open+li+li+li+li>a,
	#block-menu-block-3 .nav li.open+li+li+li+li+li>a,
	#block-menu-block-3 .nav li.open+li+li+li+li+li+li>a,
	#block-menu-block-3 .nav li.open+li+li+li+li+li+li+li>a,
	#block-menu-block-3 .nav li.open~li>a {
		background: #ffffff;
		color: #d61524;
		margin-right: 0;
	}
	#block-menu-block-3 .nav>li.open>a,
	#block-menu-block-3 .nav>li.open>a:hover,
	#block-menu-block-3 .nav>li.open>a:focus,
	#block-menu-block-3 .nav>li.open>span,
	#block-menu-block-3 .nav>li.open>span:hover,
	#block-menu-block-3 .nav>li.open>span:focus {
		border-color: #d61524;
	}
	#block-menu-block-3 .nav>li.active-trail.first>a,
	#block-menu-block-3 .nav>li.open.first>a,
	#block-menu-block-3 .nav>li.open.first>a:hover,
	#block-menu-block-3 .nav>li.open.first>a:focus,
	#block-menu-block-3 .nav>li.active-trail.first>span,
	#block-menu-block-3 .nav>li.open.first>span,
	#block-menu-block-3 .nav>li.open.first>span:hover,
	#block-menu-block-3 .nav>li.open.first>span:focus {
		margin-right: 0;
	}
	#block-menu-block-3 .nav>li ul.dropdown-menu,
	#block-menu-block-3 .nav>li ul.dropdown-menu li {
		width: 100%;
	}
	#block-menu-block-3 .nav>li ul.dropdown-menu li ul {
		width: 25%;
		float: left;
		padding: 20px;
	}
	#block-menu-block-3 .nav>li ul.dropdown-menu:after,
	#block-menu-block-3 .nav>li ul.dropdown-menu:before {
		background: #d61524;
		width: 2000px;
		content: "";
		height: 100%;
		position: absolute;
	}
	#block-menu-block-3 .nav>li ul.dropdown-menu:before {
		left: -2000px;
	}
	#menu-bg,
	#block-menu-block-3 .nav>li ul.dropdown-menu {
		background: #d61524;
		margin: 0;
		position: absolute;
		top: 50px;
		border: none;
		box-shadow: none;
		border-radius: 0;
		padding: 0;
		width: 100%;
		z-index: 1000;
		left: -20px;
	}
	#menu-bg a,
	#block-menu-block-3 .nav>li ul.dropdown-menu a {
		color: #ffffff;
		display: block;
		font-size: 20px;
		line-height: 20px;
		padding: 6px 0;
	}
	#block-menu-block-3 .nav>li ul.dropdown-menu span.nolink {
		font-size: 20px;
		color: #fff;
		text-transform: uppercase;
		display: block;
		margin-bottom: 3px;
		font-family: 'Abel-Regular', sans-serif;
		border-bottom: 3px solid;
	}
	#menu-bg a:hover,
	#menu-bg a:focus,
	#menu-bg a:active,
	#block-menu-block-3 .nav>li ul.dropdown-menu a:hover,
	#block-menu-block-3 .nav>li ul.dropdown-menu a:focus,
	#block-menu-block-3 .nav>li ul.dropdown-menu a:active {
		text-decoration: underline;
	}
	.carousel-inner>.item>img,
	.carousel-inner>.item>a>img {
		height: 390px;
	}
	.hp-slider-overlay {
		background: rgb(0, 0, 0);
		background: rgba(0, 0, 0, 0.6);
		top: auto;
		padding: 20px;
		width: 400px;
		height: 250px;
		bottom: 40px;
		left: 20px;
		text-align: left;
	}
	.hp-slider-subtitle {
		color: white;
		font-size: 18px;
		line-height: 20px;
		padding: 20px 0;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
		max-height: 100px;
	}
	.carousel-control {
		background-color: #d61524;
		background-position: center center;
		top: auto;
		bottom: 0;
		left: auto;
		right: 0;
		cursor: pointer;
		opacity: 1;
		height: 45px;
		width: 45px;
		display: block;
	}
	.carousel-control.left {
		background-image: url(/sites/all/themes/unimib/img/arrowbackwhite.png);
		background-repeat: no-repeat;
		right: 48px;
	}
	.carousel-control.right {
		background-image: url(/sites/all/themes/unimib/img/arrowwhite.png);
		background-repeat: no-repeat;
	}
	#overlay {
		top: 0;
	}
	#overlay .view-notizie-eventi {
		padding-left: 6.5%;
	}
	.bannernews .titolonews a {
		font-size: 32px;
	}
	#block-jquery-social-stream-jquery-social-stream .stream li .section-thumb {
		height: 142px;
	}
	#block-jquery-social-stream-jquery-social-stream .stream li .section-thumb img {
		min-height: 142px;
	}
	.dcsns-toolbar {
		top: 107px;
	}
	.dcsns-content {
		height: 220px !important;
	}
	.paragraphs-item-homebox {
		height: 426px;
		overflow: hidden;
	}
	.paragraphs-item-homebox.smallbox {
		height: 260px;
	}
	.paragraphs-item-homebox .file-video {
		padding: 100px 0 80px;
	}
	.paragraphs-item-homebox .homebox-content {
		max-height: 338px;
		overflow: hidden;
	}
	.paragraphs-item-homebox.smallbox .homebox-content {
		max-height: 184px;
	}
	.paragraphs-item-homebox.bgimg .homebox-content {
		height: 426px;
	}
	.group-right .paragraphs-item-homebox.smallbox {
		height: 260px;
	}
	.group-right .paragraphs-item-homebox.smallbox .homebox-content {
		max-height: 184px;
	}
	.hititle .titolo_box_2 {
		font-size: 45px;
		top: 156px;
	}
	.paragraphs-item-testo.paragrafo-citazione .field-name-field-testo-paragrafo {
		font-size: 30px;
	}
	.table-like-row,
	.table-like-head {
		display: table-row;
	}
	.table-like-cell {
		word-wrap: break-word;
		float: left;
		padding: 20px;
	}
	.table-like-cell.field-name {
		font-size: 18px;
		font-family: Mako,Helvetica,sans-serif,Arial;
	}
	.table-like-cell .field-label {
		display: none;
	}
}

@media screen and (min-width:1360px) {
	.container {
		/*width: 1280px;*/
		margin-left: auto;
		margin-right: auto;
		position: relative;
		padding-top: 2px;
    	padding-bottom: 2px;
	}
	.news_bar .container {
		padding: 0;
	}
	.region-sub-footer,
	.region_footer_wrapper,
	.region_footer_bottom,
	.region-header-top,
	.overlay-content-wrapper {
		max-width: 1280px;
		margin: 0 auto;
	}
	.carousel-inner>.item {
		height: 441px;
		overflow: hidden;
	}
	.carousel-inner>.item>img,
	.carousel-inner>.item>a>img {
		min-height: 441px;
		height: auto;
	}
	.hp-slider-overlay {
		bottom: 40px;
		width: 435px;
		padding: 30px;
		left: 10px;
	}
	#block-menu-block-16 {
		position: relative;
		left: -10px;
	}
	#overlay {
		top: 51px;
	}
	.overlay-content-wrapper {
		position: relative;
	}
	#overlay .taxonomy-term.vocabulary-aree-disciplinari {
		padding-left: 50px;
	}
	#overlay .view-notizie-eventi {
		padding: 20px 0 20px 50px;
		top: 204px;
	}
	#overlay .view-notizie-eventi .views-row {
		padding: 0 10px;
	}
	#overlay .date-display-single {
		font-size: 18px;
	}
	#overlay .view-notizie-eventi a {
		font-size: 15px;
	}
	#overlay .field-name-field-numero {
		font-size: 55px;
		line-height: 55px;
	}
	#overlay .field-name-field-tipologia {
		font-size: 30px;
		line-height: 30px;
	}
	#block-jquery-social-stream-left-wrapper {
		width: 390px;
	}
	#block-jquery-social-stream-wrapper {
		padding-left: 410px;
	}
	#block-views-banner-block .views-field-title a {
		padding: 20px 15px;
	}
	#block-views-banner-block .views-field-title a .titolo_banner {
		font: 40px/32px 'Abel-Regular', sans-serif;
	}
}

.morecat {
	background-color: #CFCFCF;
	border: none;
	padding-left: 10px;
}

.morecat .more-link {
	color: green;
	padding-left: 10px;
	padding: 8px 20px;
}

.morecat .more-link a {
	color: #000000;
}

div.horizontal-tabs,
#quicktabs-eventi .quicktabs-tabs {
	border: none;
	margin: 0 0 30px;
}

#quicktabs-eventi .quicktabs-tabs:after {
	content: " ";
	display: table;
	clear: both;
}

.horizontal-tabs ul.horizontal-tabs-list,
#quicktabs-eventi .quicktabs-tabs {
	background-color: #CFCFCF;
	border: none;
	padding-left: 10px;
}

.horizontal-tabs fieldset.horizontal-tabs-pane {
	padding: 0;
}

.horizontal-tabs ul.horizontal-tabs-list li,
#quicktabs-eventi .quicktabs-tabs li {
	background: none;
	border-right: none;
	min-width: auto;
	padding: 0;
	margin: 0 0 0 10px;
	float: left;
}

.horizontal-tabs ul.horizontal-tabs-list li.selected,
#quicktabs-eventi .quicktabs-tabs li.active {
	background-color: transparent;
	padding: 0;
}

.horizontal-tabs ul.horizontal-tabs-list li a,
#quicktabs-eventi .quicktabs-tabs li a {
	padding: 15px 0;
	color: #000000;
}

.horizontal-tabs ul.horizontal-tabs-list li a:after {
	content: "|";
	display: inline-block;
	padding: 0 0 0 5px;
	color: #000000;
}

.horizontal-tabs ul.horizontal-tabs-list li.last a:after {
	display: none;
	content: "";
}

.horizontal-tabs ul.horizontal-tabs-list li a:hover,
#quicktabs-eventi .quicktabs-tabs li a:hover {
	background-color: transparent;
}

.horizontal-tabs ul.horizontal-tabs-list li:hover,
.horizontal-tabs ul.horizontal-tabs-list li:focus {
	background-color: transparent;
}

#quicktabs-eventi .quicktabs-tabs li:hover,
#quicktabs-eventi .quicktabs-tabs li:focus,
#quicktabs-eventi .quicktabs-tabs li.active {
	background: #d61524;
}

.horizontal-tabs ul.horizontal-tabs-list li.selected a {
	padding: 15px 0;
	color: #A71D3E;
}

#quicktabs-eventi .quicktabs-tabs li a {
	padding: 8px 20px;
	display: block;
	text-decoration: none;
}

#quicktabs-eventi .quicktabs-tabs li.active a,
#quicktabs-eventi .quicktabs-tabs li a:hover {
	color: #ffffff;
	text-decoration: none;
}

.horizontal-tabs ul.horizontal-tabs-list li strong,
#quicktabs-eventi .quicktabs-tabs li strong {
	font-family: Mako,Helvetica,sans-serif,Arial;
}

.horizontal-tabs ul.horizontal-tabs-list .selected strong,
.horizontal-tabs ul.horizontal-tabs-list li strong:hover,
#quicktabs-eventi .quicktabs-tabs .active strong,
#quicktabs-eventi .quicktabs-tabs li strong:hover {
	font-family: "Abel-Regular", sans-serif;
	color: #A71D3E;
}

div.field-group-htabs-wrapper .field-group-format-wrapper {
	padding: 0;
}

.horizontal-tabs-panes .panel-body {
	padding: 0;
}

.view-grouping-header {
	background: #F2F2F2;
	border: none;
	border-radius: 0;
	color: #000000;
	font-size: 30px;
	font-family: Mako,Helvetica,sans-serif,Arial;
	line-height: 1;
	padding: 15px 60px 15px 20px;
	margin-bottom: 2px;
}

.view-grouping-header:before,
.nested-accordion:before {
	content: none;
}

.view-grouping-header:after,
.nested-accordion:after {
	content: "\232A";
	position: absolute;
	top: 16px;
	right: 20px;
	cursor: pointer;
}

.ui-state-default:after {
	content: "\232A";
	position: absolute;
	right: 20px;
	cursor: pointer;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

#block-views-ugov-view-degreecourse-block-11 .ui-state-default:after,
#ugov-of-cds-ugov-accordion .ui-state-default:after,
#block-views-area-disciplinari-block .ui-state-default:after {}

.aprichiudi-opzione-parent .titolo_paragrafo {
	cursor: pointer;
}

.aprichiudi-opzione-parent .titolo_paragrafo.ui-state-default::after {
	right: 35px;
}

.nested-accordion:after {
	content: "\FE40";
	top: 28px;
}

.ui-state-active:after {
	content: "\FE40";
	top: 40px;
}

#block-views-ugov-view-degreecourse-block-11 .ui-state-active:after,
#ugov-of-cds-ugov-accordion .ui-state-active:after,
#block-views-area-disciplinari-block .ui-state-active:after {
	content: "\FE40";
}

.view-grouping .view-grouping-content {
	padding: 10px 20px;
	margin-bottom: 30px;
}

.ui-accordion .ui-accordion-header {
	padding: inherit;
}

.ui-accordion .ui-accordion-icons {
	padding: 15px 45px 15px 20px;
	line-height: 1;
}

.ui-accordion .ui-accordion-noicons {
	padding: inherit;
}

.ui-accordion .ui-accordion-icons .ui-accordion-icons {
	padding: inherit;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
	display: none;
}

.ui-accordion .ui-accordion-content {
	border: none;
	padding: 20px;
}

.ui-widget {
	font-family: Mako,Helvetica,sans-serif,Arial;
	font-size: 100%;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-family: Mako,Helvetica,sans-serif,Arial;
	font-size: 100%;
}

.ui-widget-content {
	border: 1px solid #aaa;
	color: #222;
}

.ui-widget-content a {
	color: #000000;
}

.ui-widget-content .white a {
	color: #ffffff;
}

.ui-widget-header {
	background: none;
	border: none;
	color: #000000;
	font-weight: normal;
}

.ui-widget-header a {
	color: #000;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	background: #f2f2f2;
	border: none;
	color: #000;
	font-weight: normal;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
	color: #000;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
	background: #f2f2f2;
	border: none;
	color: #000;
	font-weight: normal;
}

.ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-widget-header .ui-state-focus {
	background: #f2f2f2;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited {
	color: #000;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
	background: #f2f2f2;
	border: none;
	color: #000;
	font-weight: normal;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
	color: #000;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
	background: none;
	border: none;
	color: #000;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
	color: #000;
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
	font-weight: 700;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
	font-weight: 400;
	opacity: 0.7;
}

.ui-icon {
	display: none;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
	border-top-left-radius: 0;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
	border-top-right-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
	border-bottom-left-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
	border-bottom-right-radius: 0;
}

.pagination {
	border-radius: 0;
}

.pagination>li>a,
.pagination>li>span {
	color: #d61524;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
	color: #8E1A32;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
	background-color: #d61524;
	border-color: #d61524;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
	border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
	border-bottom-right-radius: 0px;
	border-top-right-radius: 0px;
}

.pager li>a,
.pager li>span {
	border-radius: 0px;
}

#cboxNext,
#cboxPrevious,
#cboxCurrent {
	display: none;
}

#google-cse-results .cse .gsc-control-cse,
#google-cse-results .gsc-control-cse {
	padding: 0;
	font-family: Mako,Helvetica,sans-serif,Arial;
	border: none;
	font-size: 18px;
}

#google-cse-results .gsc-control-cse .gsc-table-result {
	font-family: Mako,Helvetica,sans-serif,Arial;
}

#google-cse-results .gsc-tabsArea {
	border: none;
	background: #cfcfcf;
	height: auto;
	font-size: 18px;
	padding-left: 10px;
}

#google-cse-results .gsc-tabsArea>.gsc-tabHeader {
	height: auto;
}

#google-cse-results .gsc-tabsArea>div {
	overflow: visible;
}

#google-cse-results .gsc-tabsArea .gsc-tabHeader {
	display: inline-block;
	font-weight: normal;
	font-family: 'Abel-Regular', sans-serif;
	height: auto;
	line-height: 1.2;
	padding: 8px 20px;
}

#google-cse-results .gsc-tabsArea .gsc-tabHeader.gsc-tabhActive,
#google-cse-results .gsc-tabsArea .gsc-tabHeader.gsc-tabhInactive:hover {
	border: none;
	color: #ffffff;
	background-color: #d61524;
	font-family: 'Abel-Regular', sans-serif;
}

#google-cse-results .gsc-tabsArea .gsc-tabHeader.gsc-tabhInactive {
	background-color: transparent;
	border: medium none;
	color: #333;
	font-family: 'Abel-Regular', sans-serif;
}

#google-cse-results .gsc-refinementsArea .gsc-refinementHeader {
	color: #333;
	font-weight: normal;
	font-family: Mako,Helvetica,sans-serif,Arial;
	font-size: 16px;
}

#google-cse-results .gsc-refinementsArea .gsc-refinementHeader.gsc-refinementhActive {
	color: #d61524;
}

#google-cse-results .gsc-refinementsArea {
	background: #efefef;
	padding-bottom: 10px;
	padding-top: 10px;
	padding-left: 12px;
}

#google-cse-results .gcsc-branding {
	display: none;
}

#google-cse-results .gsc-above-wrapper-area {
	padding: 5px 12px;
}

#google-cse-results .gsc-results {
	width: 100%;
}

#google-cse-results .gsc-webResult.gsc-result {
	padding: 0;
}

#google-cse-results .gsc-context-box {
	border-bottom: 1px solid #ddd;
	font-size: 100%;
	padding: 10px 20px;
	margin-bottom: 10px;
}

#google-cse-results .gsc-context-box .gsc-facet-label {
	color: #d61524;
	text-decoration: none;
}

#google-cse-results .gs-webResult.gs-result {
	padding: 15px 10px;
	background: none;
	border-bottom: 1px solid #ebebeb;
}

#google-cse-results .gsc-table-result,
#google-cse-results .gsc-thumbnail-inside,
#google-cse-results .gsc-url-top {
	padding: 0;
}

#google-cse-results .gs-promotion {
	padding: 20px;
}

#google-cse-results .gs-promotion a.gs-title:link *,
#google-cse-results .gs-result .gs-title,
.gs-result .gs-title *,
#google-cse-results .gs-webResult.gs-result a.gs-title:link,
#google-cse-results .gs-webResult.gs-result a.gs-title:link b,
#google-cse-results .gs-imageResult a.gs-title:link,
#google-cse-results .gs-imageResult a.gs-title:link b {
	color: #d61524;
	font-size: 22px;
}

#google-cse-results .gs-webResult div.gs-visibleUrl,
#google-cse-results .gs-imageResult div.gs-visibleUrl {
	color: #000;
	font-style: italic;
}

#google-cse-results .gsc-imageResult-column,
#google-cse-results .gsc-imageResult-classic {
	margin-bottom: 0;
	padding: 0;
}

#google-cse-results .gsc-result.gsc-imageResult.gsc-imageResult-classic {
	margin-bottom: 0;
	padding: 15px 10px;
	border-bottom: 1px solid #ebebeb;
}

#google-cse-results .gs-imageResult .gs-image-box {
	height: 120px;
}

#google-cse-results .gsc-imageResult.gsc-result {
	margin-right: 0;
	width: 33.3333%;
}

#google-cse-results .gsc-webResult.gsc-result:hover {
	border-color: #ffffff;
	background: #f2f2f2;
}

#google-cse-results .gsc-results .gsc-cursor-box {
	background: #efefef none repeat scroll 0 0;
	margin: 0;
	padding: 10px;
}

#google-cse-results .gsc-results .gsc-cursor-box .gsc-cursor-page,
#google-cse-results .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
	color: #d61524;
	background: none;
}

@media all and (max-width:767px) {
	#google-cse-results .gsc-context-box,
	#google-cse-results .gsc-above-wrapper-area {
		display: none;
	}
	#google-cse-results .gsc-imageResult.gsc-result {
		width: 100%;
	}
}

.toplink-button {
	display: block;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	background: #d61524;
	color: white!important;
	text-align: center;
	line-height: 30px;
	z-index: 10000;
	text-transform: uppercase;
	padding: 7px 0;
	font-weight: bold;
	text-decoration: none;
}

.toplink-button:hover {
	text-decoration: none;
}

.toplink-modal.is-closed {
	display: none;
}

.toplink-modal {
	display: block;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
/* 	-o-transform translate(-50%, -50%); */
	background: white;
	z-index: 10000000;
	width: 80%;
}

.toplink-overlay {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1000000;
}

@media all and (min-width:767px) {
	.toplink-modal {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
/* 		-o-transform translate(-50%, -50%); */
		max-width: 500px;
	}
}

@media all and (min-width:1200px) {
	.toplink-button {
		display: none;
	}
}

.toplink-modal-header,
.toplink-modal-top {
	background: #d61524;
	color: white;
}

.toplink-modal-header {
	padding: 5px 30px;
}

.toplink-modal-header a {
	color: white;
	float: right;
	font-size: 38px;
}

.toplink-modal-header a:hover {
	color: white;
	text-decoration: none;
}

.toplink-modal-top {
	padding: 25px 30px;
}

.toplink-modal-title {
	text-align: center;
	text-transform: uppercase;
	font-size: 26px;
	font-weight: bold;
	padding: 15px 0 10px;
	border-bottom: 2px solid white;
}

.toplink-modal-text {
	margin-top: 10px;
}

.toplink-modal-link {
	padding: 20px 30px;
	column-gap: 32px;
}

.toplink-modal-link a {
	color: black;
	text-decoration: underline;
	font-size: 20px;
	line-height: 40px;
}

@media all and (min-width:1200px) {
	.toplink-modal-link {
		padding: 20px 30px;
		column-count: 2;
		column-gap: 40px;
	}
}

@media(min-width:1200px),
(orientation:landscape) {
	.toplink-modal-link {
		padding: 20px 30px;
		column-count: 2;
		column-gap: 40px;
	}
}

.field-name-field-paragrafo .view-grouping .view-grouping-header::after {
	content: "\232A";
	cursor: pointer;
	position: relative;
	top: 0;
	right: 0;
	margin-right: -50px;
	float: right;
}

.field-name-field-paragrafo .view-grouping .view-grouping-header.menu-open::after {
	content: "\FE40";
	cursor: pointer;
	position: relative;
	top: 0;
	right: 0;
	margin-right: -50px;
	float: right;
}

.field-name-field-paragrafo .view-grouping .view-grouping-content h3 {
	cursor: pointer;
	margin-top: 2px;
	padding: 15px 45px 15px 20px;
	margin-bottom: 2px;
	background: #f2f2f2;
	border: none;
	color: #000;
	font-weight: normal;
	font-size: 1em;
}

.field-name-field-paragrafo .view-grouping .view-grouping-content,
.field-name-field-paragrafo .view-grouping .view-grouping-content.displayed div {
	display: none;
}

.field-name-field-paragrafo .view-grouping .view-grouping-content.displayed,
.field-name-field-paragrafo .view-grouping .view-grouping-content.displayed>div.displayed,
.field-name-field-paragrafo .view-grouping .view-grouping-content.displayed>div.displayed div {
	display: block !important;
}

.field-name-field-paragrafo .view-grouping .view-grouping-content {
	padding: 10px 20px;
	margin-bottom: 30px;
}

.field-name-field-paragrafo .view-grouping .view-grouping-content h3::after {
	content: "\232A";
	cursor: pointer;
	position: relative;
	top: 50%;
	right: 20px;
	margin-right: -50px;
	float: right;
}

.field-name-field-paragrafo .view-grouping .view-grouping-content.displayed h3.menu-open::after {
	content: "\FE40";
}

.field-name-field-paragrafo .view-grouping .view-grouping-content.displayed>div.displayed>div {
	padding-left: 10px;
}

.field-name-field-paragrafo .view-grouping .view-grouping-content.displayed>div.displayed .view-display-id-attachment_1 span.docente::before {
	display: block;
	content: "Docenti responsabili:";
}

html {
    background: white;
    height: 100%;
}

body {
    color: #565656;
    font-family: Mako,Helvetica,sans-serif,Arial;
    font-size: 78%;
    padding: 0px;
    margin: 0px;
    height: 100%;
}
#main {
	background-color: white;
}
.footer {
    background: url(../images/bg-footer.png) #f2f2f2 repeat-x bottom;
	text-decoration: none;
    color: #59a5ca;
}
.navbar, .footer {
    margin-left: auto;
    margin-right: auto;
}

.navbar-default {
    background-color: transparent;
}

.card {
    padding: 15px 8px;
}

@media (min-width: 768px) {
	.container, .navbar, .footer {
	    width: 750px;
	}
}
@media (min-width: 992px) {
	.container, .navbar, .footer {
    	width: 970px;
	}
}
@media (min-width: 1200px) {
	.container, .navbar, .footer {
	    width: 1170px;
	}
}

footer.footer {
	display: none;
}

/*
@media (min-width: 320px) {
	body {
		background: url(../images/visual-body-720.png) no-repeat center 0px;
		width: 900px;
	}
	#main, .navbar, .footer {
		width: auto;
	}
}
@media (min-width: 720px) {
	body {
		background: url(../images/visual-body-720.png) no-repeat center 0px;
		width: auto;
	}
	#main, .navbar, .footer {
		width: 710px;
	}
}
@media (min-width: 768px) {
	.card h4 {
		margin-top: 0px;
	}
}
@media (min-width: 986px) {
	body {
		background: url(../images/visual-body.png) no-repeat center -3px;
		width: 1000px;
	}
	#main, .navbar, .footer {
		width: 886px;
	}
}
@media (min-width: 1000px) {
	body {
		background: url(../images/visual-body.png) no-repeat center -3px;
		width: 100%;
	}
}
@media (min-width: 1100px) {
	body {
		background: url(../images/visual-body.png) no-repeat center -3px;
		width: auto;
	}
}
*/
@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700');
 
/***GENERALE***/

html {
  overflow-x: hidden;
}

/*
body,h1,h2,h3,h4,h5,h6,span,strong,bold,a,li {
  font-family: Mako,Helvetica,sans-serif,Arial;
}
*/

h1,h2,a {
  font-family: Mako,Helvetica,sans-serif,Arial;
  font-weight:bold;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}


/***BOTTONI**

.btn,.btn-primary {
  background-color: #d61524;
  border-radius: 3px;
}

.btn:focus,.btn:hover {
  border-radius: 3px;
  background: #B1B8BE;
  color: #000000;
}

.avanti {
  margin-top: 30px;
}

a.white {
  color: white;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  font-weight: bold;
  height: 50px;
}

a img {
  cursor: pointer;
}

.link {
  font-size: 15px;
  font-weight: bold;
  display: contents;
}

#donotcache-form-wrapper {
  margin-bottom: 0px;
}
*/

/***DROPDOWN***/

/*
.open>.dropdown-menu {
  display: block;
  background: #d61524;
  border-radius: 0;
}
*/
.dropdown-menu li {
  border-right: none;
  width: auto;
}

.intro-text {
  font-weight: bold;
  color: #d61524;
  margin-bottom: 30px;
  font-size: 49px;
  text-align: center;
}

.navbar-collapse #block-menu-block-34 .nav>li>a, .navbar-collapse #block-menu-block-1 .nav>li>a {
  border-color: #ffffff;
  color: #ffffff;
  background: #d61524;
  font-family: inherit;
  padding-right: 20px;
  border-bottom: 1px solid white;
}

.nav span.caret {
  display: inline-block!important;
}

.open>.dropdown-menu {
  display: block;
  color: #d61524;
  border-radius: 0;
  margin-top: 0;
  border: none;
  box-shadow: none;
}

.dropdown-menu>li>a {
  font-size: 20px;
}

ul.dropdown-menu li {
  border-right: none!important;
  width: auto;
}


/***MODAL***/

/*
.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

#exampleModalCenter .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

#exampleModalCenter .modal-header {
  padding-left: 30px;
  border-bottom: none;
  background: #d61524;
  color: white;
  padding-right: 30px;
}

#exampleModalCenter .modal-header .close {
  right: 15px;
  top: 15px;
  font-size: 38px;
  font-weight: 300;
  opacity: 1;
  color: white;
}

#exampleModalCenter .modal-body {
  padding-top: 30px;
  padding-bottom: 15px;
}

#exampleModalCenter .modal-content {
  border-radius: 0;
  box-shadow: none;
}

.modal-backdrop {
  opacity: 0.7!important;
}

*/

/***FORM***/


form label {
    /* labels are hidden */
  font-weight: bold;
}

form legend {
  font-size: 1.2em;
  margin-bottom: 12px;
}

.form-element-wrapper {
  margin-bottom: 12px;
}

.form-element {
  width: 100%;
  padding: 13px 12px;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}

.form-element:focus {
  outline: dotted thin;
}

.form-field {
  color: #757575;
  ;																																				
     border: 1px solid #B7B7B7;
}

.form-field-focus, .form-field:focus, input[type="text"]:focus,select:focus {
  color: #333;
  border: 1px dotted gray;
}

.form-button {
  background: #A1143E;
  FONT-SIZE: 20PX;
  color: #ffffff;
  cursor: pointer;
}

.form-button:hover {
  background: #B1B8BE;
  color: #000000;
}

.form-error {
  padding: 0;
  color: #B61601;
}

input[type=checkbox] {
  vertical-align: middle;
  width: 10px;
  margin: 0px;
}

/*
input {
  min-width: 95%!IMPORTANT;
}
*/

input[type=date] {
  -webkit-appearance: unset;
}


/***VALIDATION***/

.validation {
  padding: 10px;
  margin-top: 10px;
}

.validation p {
  color: #ff2000;
  font-weight: bold;
  margin: 0;
}

/****
.has-error{
	background: #fff09f!important;
    padding-bottom: 20px!important;
	
	
}
***/



/***BOTTONE SPID***/
 
	
.spid-idp-button {
  position: initial!important;
  z-index: 1039;
}

.spid-idp-button .spid-idp-button-menu, .spid-idp-button .spid-idp-button-panel {
  border: none;
  box-shadow: none;
}

.spid-idp-button-link {
  border-bottom: 1px solid #bbbbbb;
  padding-top: 5px;
  padding-bottom: 5px;
}

.modal img {
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button-spid {
  background-color: #06C;
  color: #FFF;
  width: 100%;
  border-radius: 5px;
}

.italia-it-button-text {
  padding: .95em 1em .85em 1em;
  font-size: 1.15em;
  text-align: center;
  FLOAT: NONE;
}


 


/****MEDIA QUERIES***/

/***<380px***/

@media (max-width:380px) {
  #separator {
    border-top: 5px solid #d61524;
    width: 100%;
    margin-top: 50px!important;
  }

  .logo img {
    width: 60px;
  }

  h1.header {
    margin-top: -0px;
  }

  h2.intro-text {
    margin-bottom: 30px;
    font-size: 23.7px;
  }

  header a.unito {
    font-size: 14px;
  }
}

/***<640px***/

@media (max-width:640px) {
  #separator {
    border-top: 5px solid #d61524;
    width: 100%;
    margin-top: 0px;
  }

  header .nav .open>a, .nav .open>a:hover, .nav .open>a:focus {
    background-color: transparent;
    border-color: #d61524;
    text-align: right!important;
    font-family: inherit;
  }

  ul.menu.nav > li {
    float: none;
  }

  li {
    list-style: none;
  
    text-align: right;
  }

  h1.header {
    position: absolute;
    right: 15px;
    top: -15PX;
  }

  a.unito {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  .intro-text {
    margin-bottom: 30px;
    font-size: 26px;
  }

  .form-wrapper {
    margin-top: 8%;
    padding-bottom: 100px;
    margin-bottom: 100px;
    padding: 0;
  }

  .form-group {
    margin-bottom: 20px;
    padding: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  .modal-footer .btn {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    /* margin-left: 5px; */;
  }

  .modal-footer .btn+.btn {
    margin-bottom: 0;
    margin-left: 0;
    /* margin-left: 5px; */;
  }

  .cache-consent {
    font-size: 14px;
  }

  #seconda-colonna {
    margin-top: 20px;
  }
}


/***>641px <991px***/

@media (min-width:641px) and (max-width:991px) {
	
  #seconda-colonna {
    margin-top: 20px;
  }
}
 
/*** >768px***/


@media (min-width:768px) {
  .navbar-collapse {
    top: 80px;
  }
} 

/***>992***/

@media (min-width: 992px) {
  .navbar-default .navbar-collapse {
    padding-bottom: 0;
    border-bottom: none;
    width: 100%;
  }

  .menu.nav .navbar-collapse .nav > li > a, .navbar-collapse .nav > li > span {
    font-family: inherit!important;
    font-size: 21px;
    text-align: right;
    border-top: 1px solid #d61524;
  }
  
  

  #block-menu-block-11 .nav>li>a {
    font-family: Mako,Helvetica,sans-serif,Arial;
    color: #ffffff;
    padding: 20px;
    font-weight: bold;
  }

  #block-menu-block-11 li {
    border-right: none;
    width: auto;
  }
}



 

/***>640px  <1199px***/


@media (min-width:640px) and (max-width:1199px) {
  #separator {
    border-top: 5px solid #d61524;
    width: 100%;
    margin-top: 0px;
  }

  ul.menu.nav > li {
    float: none;
  }

  ul.menu.nav > li.home {
    float: left;
  }

  li {
    list-style: none;
     
    text-align: right;
  }
     

h1.header {
  position: absolute;
  right: 15px;
  top: -35PX;
}

a.unito {
  font-size: 30px;
  font-weight: bold;
  color: white;
}

.form-wrapper {
  margin-top: 8%;
  padding-bottom: 100px;
  margin-bottom: 100px;
  padding: 0;
}
	
	 
	
}

#navbar {
  height: 54px;
}

#logo-myunito {
  height: 54px;
  width: auto;
}

#logo-unito img {
	height: 54px;
	width: auto;
}

@media (max-width:543px) {
	#logo-myunito img {
		height: 54px;
		width: auto;
	}
 
	#logo-unito img {
		height: 54px;
		width: auto;
	}
}

/***>=544px***/
@media (min-width:544px) {
	 #navbar {
		height: 104px;
	 }

	#logo-myunito img {
		height: 54px;
		width: auto;
		/*margin-top: -20px;*/
	}
	
	#logo-myunito {
		height: 104px;
		align-items: center;
    	display: flex;
	}
	
	#logo-unito img {
		height: 104px;
		width: auto;
	}
}


/***>768px***/

@media (min-width:768px) {
     
  .card {
    border: 1px solid #bbbbbb;
  }
}


/***<1024px***/

@media (max-width:1024px) {
  .nav>li>a {
    color: white;
  }

  .nav>li.home {
    float: left!important;
    font-weight: bold;
    border-right: 1px solid;
  }

  p.footer_text {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}


/***768px X 1024px***/

@media (device-width:768px) and (device-height:1024px) {
	
  .form-wrapper {
    min-height: 590px!important;
  }
}


/***<1199px***/


@media (max-width:1199px) {
	
  .navbar-default .navbar-collapse {
    padding-bottom: 0;
    border-bottom: none;
  }
}


/***>1200px***/



@media (min-width:1200px) {
  h1.header {
    position: absolute;
    right: 0;
    margin-top: -15px;
  }

  a.unito {
    font-size: 35px;
    color: white;
    font-weight: bold;
    margin-right: 15px;
  }

  .intro-text {
    font-size: 49px;
  }

  form {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 21px;
  }

  .form-wrapper {
    padding-bottom: 100px;
    margin-bottom: 30px;
    padding: 0;
  }

  .center-block {
    padding-bottom: 6px;
  }

  #modal-form,.registration-form {
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }

  .padding-bottom-17 {
    padding-bottom: 17px;
  }

  .card {
    border: 1px solid #bbbbbb;
  }

  .avatar {
    width: 100%;
    height: 160px;
  }

  .validation p {
    font-size: 18px;
  }
}

.toplink-modal-title {
  font-size: 30px;
}


/***>1600px***/

@media (min-width: 1600px) {

  footer {
    width: 100%;
    bottom: 0;
  }
}


/*** 2048 X 1536px ***/ 


@media (width:2048px) and  (height:1536px) {
  .form-wrapper {
    margin-top: 20%!important;
    padding-bottom: 100px;
    margin-bottom: 120px;
    padding: 0;
  }
}







 

/**********************************************

******************* LANDING *******************

**********************************************/


.btn-unimib{
	background-color: #d61524;
	color: #ffffff;
}
.btn-unimib:hover, .btn-unimib:focus{
	color: #ffffff;
	background-color: #1b2736;
	border-color: #141b21;
}
.alert-unimib{
	border: 2px solid #d61524;
    border-radius: 5px;
    padding: 25px;
}
.panel-unimib{
	border-color: #d61524 !important;
}
.panel-heading-unimib{
	background-color: #d61524;
	color: #ffffff;
	padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.pagination > li > a{
	color: #d61524;
}
.pagination > .active > a{
	background-color: #d61524;
	color: #ffffff;
	border-color: #d61524;
}
.pagination > .active > a:hover{
	color: #ffffff;
	background-color: #1b2736;
	border-color: #141b21;
	cursor: pointer;
}
.label-unimib{
	background-color: #d61524;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-unimib,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-unimib {
  background-color: #d61524;
  color: #ffffff;
}
.angular-notifications-icon  {
	right: auto !important;
	left: -10px !important;
	top: -7px !important;
	padding-top: 0px !important;
	min-width: 20px !important;
	height: 20px !important;
	background: #d61524 !important;
	color: #ffffff !important;
}
.navbar-default .navbar-brand {
    color: #ffffff;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #d61524;
    background-color: #ffffff;
    line-height: 2;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #ffffff;
    background-color: transparent;
}
.navbar-default .navbar-nav > li > a {
    color: #ffffff;
    line-height: 2;
}
.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: #d61524;
    outline: 0;
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
    color: #000000;
    background-color: #ffffff;
}
.modal-body p {
	line-height: 19px;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    color: #d61524;
}
.right-divider{
	border-right: 2px solid #d61524;
    min-height: 300px;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: #ffffff;
    background-color: transparent;
}

div.service-image{
	margin-bottom: 10px;
    position: relative;
    border-bottom: 1px solid #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 150px;
}

div.service-image image{
	flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}
.txt-center{
	text-align: center;
}

.float-right{
	float:right !important;
}

.txt-service-card{
	font-size: 1vw;
	font-weight:bold;
	text-align: center;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}



.post-content {
    background: none repeat scroll 0 0 #FFffff;
    opacity: 0.5;
    margin: 0 auto;
    margin-top: -54px;
    text-align: center;
    position: relative;
    width:40%;

    
}
/*
.thumbnail {
    margin:0 auto;
    text-align:center;
}*/

.wrapper {
    text-align:center;
}

.list-group-item.unimib:hover{
	background-color: #d61524;
	color: #ffffff;
}

#main{
	/*margin-bottom: 10px;*/
	min-height: 500px;
}
.btn-input-group{
	height: 44px;
	margin-left: -4px !important;
}

/*disabled*/
.form-element[disabled], fieldset[disabled] .form-element {
    cursor: not-allowed;
}
.form-element[disabled], .form-element[readonly], fieldset[disabled] .form-element {
    background-color: #eee;
    opacity: 1;
}
.table-responsive{
	overflow-x: unset;
}
.service-card{
	padding-top:0px; 
	border:1px solid #bbbbbb; 
}
p.footer_text {
    padding: 10px;
    min-height: auto;
}

.padding-20{
	padding-left:20px;
	padding-right:20px;
}

/* IframeCSS */
.iFrameContainer{
	width:100%;
	border:none;
	min-height: calc(100vh - 115px);
}


.btn-primary, .btn-primary:hover {
    color: rgb(255, 255, 255);
    background-color: #db313f;
    border-color: #db313f;
}

.btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover, .btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus, .btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus {
    background-color: #c24953;
    border-color: #c24953;
}

body .ace_editor, body .ace_editor div{
    font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}

.nav.navbar-nav.navbar-right {
	margin: 0px;
}

.navbar-collapse {
	left: 0px;
	top: 54px;
}

.navbar {
	min-height: 46px;
}